/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// react-routers components
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDAvatar from "../../../components/MDAvatar";
import MDButton from "../../../components/MDButton";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useEffect } from "react";
import { workingHoursAPI } from "../../../assets/apiFunctions";
import { calculateHours, minutes_to_hhmm, utils } from "../../../assets/assetsFunctions";
import AlertDialog from "../../../components/dialog/dialog";
import RenderHours from "../components/renderHours";
import Divider from "@mui/material/Divider";

function HoursList({ title, profiles, shadow, status }) {
  function handleDelete(id) {
    workingHoursAPI.deleteSpecificDay(id).then((r) => console.log(id));
  }
  const renderProfiles = Object.entries(profiles)?.map(([k, v], index) => (
    // style={{ backgroundColor: "rgba(255, 140, 0,0.42)" }}
    <Accordion key={index}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id={k}>
        <MDBox
          style={{
            width: "100%",
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <MDTypography variant="button" fontWeight="medium">
            {k}
          </MDTypography>
          {""}
          <MDTypography variant="button" fontWeight="medium">
            {/*{minutes_to_hhmm(calculateHours(hours))}*/}
          </MDTypography>{" "}
          {/*check if accepted or not*/}
          {status && status === "accepted" ? (
            <MDTypography variant="button" fontWeight="medium" color={"success"}>
              Accepted
            </MDTypography>
          ) : (
            <MDBox fontWeight="medium" color={"success"}>
              <MDTypography variant="h6" fontWeight="medium" color={"warning"}>
                Pending...
              </MDTypography>{" "}
            </MDBox>
          )}
        </MDBox>
      </AccordionSummary>
      <AccordionDetails>
        <MDBox component="li" display="flex" alignItems="center" mb={1}>
          {/*<MDBox mr={1}>/!*<MDAvatar src={image} alt="something here" shadow="md" />*!/</MDBox>*/}
          <MDBox
            display="flex"
            flexDirection="column"
            // alignItems="flex-start"
            // justifyContent="center"
            width={"100%"}
          >
            {Object.entries(v)
              .sort((a, b) => (a[0] < b[0] ? -1 : 1))
              .map(([key, value], index) => (
                <MDBox key={index} width={"100%"}>
                  <MDBox display="flex" flexDirection="row" gap={{ xs: 2, md: 4 }}>
                    <MDTypography variant="h6" fontWeight="medium">
                      {key}
                    </MDTypography>
                    <MDBox>
                      <RenderHours hours={value} isAdmin={true} />
                    </MDBox>
                  </MDBox>
                  <Divider orientation="horizontal" sx={{ ml: -2, mr: 1 }} color={"#94a3b8"} />
                </MDBox>
              ))}
          </MDBox>
        </MDBox>
      </AccordionDetails>
    </Accordion>
  ));
  return (
    <>
      <MDBox pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium">
          {title}
        </MDTypography>
      </MDBox>
      <MDBox>
        <MDBox component="div" display="flex" flexDirection="column" p={0} m={0}>
          {renderProfiles}
        </MDBox>
      </MDBox>
    </>
  );
}

// Setting default props for the ProfilesList
HoursList.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfilesList
HoursList.propTypes = {
  title: PropTypes.string,
  profiles: PropTypes.object.isRequired,
  shadow: PropTypes.bool,
  status: PropTypes.string,
};

export default React.memo(HoursList);
