import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

export const UsersContext = React.createContext(null);

const UsersContextProvider = (props) => {
  const [users, setUsers] = useState(null);
  function updateUsers(data) {
    setUsers([data, ...users]);
  }
  return (
    <UsersContext.Provider value={{ users, setUsers, updateUsers }}>
      {props.children}
    </UsersContext.Provider>
  );
};

UsersContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
export default UsersContextProvider;

export const getUserContextVariables = () => {
  return useContext(UsersContext);
};
