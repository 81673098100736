/* eslint-disable react/prop-types */
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import { momentHelper } from "../../../assets/momentHelper";
import React from "react";

export const Name = ({ name }) => (
  <MDBox display="flex" alignItems="center" lineHeight={1}>
    <MDBox lineHeight={1}>
      <MDTypography
        display="block"
        variant="button"
        fontWeight="medium"
        textTransform={"capitalize"}
      >
        {name}
      </MDTypography>
    </MDBox>
  </MDBox>
);
export const Shift = ({ name }) => (
  <MDBox display="flex" alignItems="center" lineHeight={1}>
    <MDBox lineHeight={1} display={"flex"} gap={2}>
      <MDTypography display="block" variant="button" fontWeight="medium">
        {momentHelper.removeSeconds(name?.from)}
      </MDTypography>
      <MDTypography display="block" variant="button" fontWeight="medium">
        {momentHelper.removeSeconds(name?.to)}
      </MDTypography>
    </MDBox>
  </MDBox>
);
