import axiosInstance from "./axiosInstance";
import axios from "axios";
import { authAPI } from "./prefixApis/authAPI";
import { prefix } from "./prefixApis/prefixsVariables";
import { patientsAPI } from "./prefixApis/patientsAPI";
import { reservationsAPI } from "./prefixApis/reservationsAPI";
import { roomsAPI } from "./prefixApis/roomsAPI";
import { departmentAPI } from "./prefixApis/departmentAPI";
import { emailsApi } from "./prefixApis/emailsApi";
import { workingHoursAPI } from "./prefixApis/workingHoursAPI";
import { usersAPI } from "./prefixApis/usersAPI";
import { carsAPI } from "./prefixApis/carsAPI";
import { shiftsApi } from "./prefixApis/shiftsApi";

const statisticsAPI = {
  getAll: async () => {
    return await axiosInstance.get(`${prefix.statistics}home`);
  },
};

const otherAPI = {
  filterPlace: async (query, str) => {
    return await axiosInstance.get(`/api/filter_places/${query}`, str);
  },
  contractTypes: async () => {
    return await axiosInstance.get(`${prefix.user}/get_contract_types`);
  },
  setPlacesToDatabase: async (data) => {
    return await axiosInstance.post(`/api/add_places_to_database`, data);
  },
  changePassword: async (data) => {
    return await axiosInstance.post(`/api/change_password`, data);
  },
  setVisitor: async (id, device) => {
    const formData = new FormData();
    await formData.append("browserId", id.toString());
    await formData.append("time", true);
    await formData.append("device", JSON.stringify(device));
    return await axios.post(
      `https://portfoilo-backend.rawad.net/public/index.php/api/setVisitor`,
      formData,
      {
        headers: {
          // "Access-Control-Allow-Origin": "http://localhost:3000",
          // 'X-Requested-With': 'XMLHttpRequest',
          "Access-Control-Allow-Credentials": true,
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      }
    );
  },
};

export {
  otherAPI,
  authAPI,
  shiftsApi,
  carsAPI,
  statisticsAPI,
  patientsAPI,
  reservationsAPI,
  roomsAPI,
  departmentAPI,
  emailsApi,
  workingHoursAPI,
  usersAPI,
};
