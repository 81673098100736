const commonProps = {
  onChange: "handleChange",
  required: false,
  type: "number",
  inputType: "input",
};

const roomDetails = [
  {
    ...commonProps,
    name: "room_name",
    label: "room name",
    value: "room_name",
    required: true,
    type: "text",
  },
  {
    ...commonProps,
    name: "qty",
    label: "Qty",
    value: "qty",
    required: true,
  },
  {
    ...commonProps,
    name: "room_type_id",
    label: "room type",
    value: "room_type_id",
    required: true,
    inputType: "select",
    options: "roomTypes",
  },
];

// const patientAddressFields = [
//   {
//     ...commonProps,
//     name: "room_description",
//     label: "room description",
//     value: "room_description",
//     required: true,
//     inputType: "textArea",
//     type: "textArea",
//   },
// ];
// const roomImage = [
//   {
//     ...commonProps,
//     name: "room_img",
//     label: "room img",
//     value: "room_img",
//     required: false,
//     type: "file",
//   },
// ];

const formFields = [
  { name: "Room Details", fields: roomDetails },
  // { name: "Room Description", fields: patientAddressFields },
  // { name: "Room Image", fields: roomImage },
];

export default formFields;
