/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
import React from "react";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import DataTable from "../../../examples/Tables/DataTable";
import { utils } from "../../../assets/assetsFunctions";
import MDButton from "../../../components/MDButton";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import PrintableData from "../../../components/printTable/printTable";

const ListView = ({ workingDays, totalHours, date }) => {
  const { calculateHours, checkHour, compareDays, minutes_to_hhmm } = utils;
  const ref = React.useRef(null);
  const Day = ({ name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      {/*<MDAvatar src={team2} name={name} size="sm" />*/}
      <MDBox lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>{" "}
        <MDTypography display="block" variant="caption" fontWeight="medium">
          {moment(name).format("dd")}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
  const RenderTotalHours = ({ value }) => {
    const x = calculateHours(value);
    return (
      <MDBox display="flex" alignItems="center" lineHeight={1}>
        <MDBox lineHeight={1}>
          <MDTypography display="block" variant="button" fontWeight="medium">
            {minutes_to_hhmm(x)}
          </MDTypography>
        </MDBox>
      </MDBox>
    );
  };
  const Hours = ({ wHours }) => (
    <MDBox lineHeight={1} textAlign="left" display={"flex"} flexDirection={"column"} rowGap={2}>
      {wHours?.map((h) => (
        <MDBox key={h.id} style={{ display: "flex", flexDirection: "column" }} rowGap={1}>
          <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
            {h.from}
          </MDTypography>{" "}
          <MDTypography
            key={h.id}
            display="block"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            {h.to}
          </MDTypography>
        </MDBox>
      ))}
    </MDBox>
  );
  const Action = ({ action, id }) => (
    <MDButton size={"small"} variant="text" color="info" onClick={() => navigate(`/edit/${id}`)}>
      {action}
    </MDButton>
  );
  const rows = workingDays
    ? workingDays.flatMap((user) => [
        {
          userRow: (
            <MDBox width={1} lineHeight={1}>
              <MDTypography display="block" variant="h6" fontWeight="bold">
                {user.user_name}
              </MDTypography>
            </MDBox>
          ),
        },
        ...user.working_days.sort(compareDays).map((workingDay) => ({
          day: <Day name={workingDay.date.date_day} />,
          hours: <Hours wHours={workingDay.hours} />,
          totalHours: <RenderTotalHours value={workingDay.hours} />,
          action: <Action action="Edit" id={workingDay.date.id} />,
        })),
      ])
    : [];
  // Flatten the rows array (since .map() returns an array of arrays)
  // const rows = flattenedRows ? [].concat(...flattenedRows) : [];

  const columns = [
    { Header: "user", accessor: "userRow", isVisible: false },
    { Header: "Day", accessor: "day", align: "left" },
    { Header: "Hours", accessor: "hours", align: "left" },
    { Header: "Total hours", accessor: "totalHours", align: "center" },
    // { Header: "Action", accessor: "action", align: "center" },
  ];
  const handlePrint = useReactToPrint({
    content: () => ref.current,
  });
  const [total, setTotal] = React.useState(0);

  // Calculate and set the total when workingDays changes
  React.useEffect(() => {
    if (workingDays) {
      let newTotal = 0;
      workingDays.forEach((user) => {
        user.working_days.forEach((workingDay) => {
          newTotal += calculateHours(workingDay.hours);
        });
      });
      setTotal(newTotal);
    }
  }, [workingDays]);
  return (
    <>
      <MDButton onClick={handlePrint}>print</MDButton>
      <div>
        <DataTable
          table={{ columns, rows }}
          isSorted={false}
          entriesPerPage={true}
          showTotalEntries={false}
          noEndBorder={false}
          customValue={minutes_to_hhmm(total)}
        />
      </div>
      <PrintableData columns={columns} rows={rows} printRef={ref} date={date} />
    </>
  );
};

export default React.memo(ListView);
