import React from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import MDTypography from "../components/MDTypography";
import MDButton from "../components/MDButton";
import MDBox from "../components/MDBox";
const NotAllowed = () => {
  return (
    <Container maxWidth="sm">
      <MDBox
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <MDTypography variant="h4" component="h1" align="center" style={{ marginTop: "100px" }}>
          Not Allowed
        </MDTypography>
        <MDTypography variant="body1" align="center">
          You do not have permission to access this page.
        </MDTypography>
        <MDButton
          variant="gradient"
          color="info"
          component={Link}
          size={"medium"}
          to="/dashboard" // Replace with the actual URL of your dashboard page
          style={{ marginTop: "20px" }}
        >
          <MDTypography variant="body" align="center" color={"white"}>
            Go to Dashboard
          </MDTypography>
        </MDButton>
      </MDBox>
    </Container>
  );
};
export default NotAllowed;
