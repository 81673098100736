import React, { useEffect } from "react";
import { shiftsApi } from "../assets/apiFunctions";
const ShiftsContext = React.createContext(null);
//eslint-disable-next-line
const ShiftsContextProvider = ({ children }) => {
  const [shifts, setShifts] = React.useState();
  const [isLoading, setIsLoading] = React.useState(true);
  useEffect(() => {
    shiftsApi
      .getShifts()
      .then((res) => setShifts(res.data.shifts))
      .finally(() => setIsLoading(false));
  }, []);
  return <ShiftsContext.Provider value={{ shifts, isLoading }}>{children}</ShiftsContext.Provider>;
};

export default ShiftsContextProvider;

export const useShiftsContextVariable = () => {
  return React.useContext(ShiftsContext);
};
