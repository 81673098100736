import React, { useState } from "react";
import SubmitForm from "../../components/forms/submitForm";
import Grid from "@mui/material/Grid";
import Label from "../../components/label/label";
import MDInput from "../../components/MDInput";
import useFormData from "../../hooks/useFormData";
import { setLoader, useMaterialUIController } from "../../context";
import { useDepartmentContext } from "../../context/departmentContext";
import useDepartmentHook from "./components/useDepartmentHook";

const AddDepartments = (props) => {
  const [name, setName] = useState("");
  const [controller, dispatch] = useMaterialUIController();
  const { departments, setDepartments } = useDepartmentContext();
  const { createOrUpdateDepartment } = useDepartmentHook();
  const submit = (e) => {
    e.preventDefault();
    if (name) {
      setLoader(dispatch, true);
      const formData = useFormData({ name: "name", data: name });
      createOrUpdateDepartment(formData).then((res) => {
        if (res !== null) {
          setDepartments([res.data.data, ...departments]);
          setName("");
        }
      });
    }
  };
  const handleChange = (e) => {
    setName(e.target.value);
  };
  return (
    <SubmitForm submit={submit}>
      <Grid container direction={"column"} spacing={2}>
        <Grid item xs={12} sm={"auto"}>
          <Label name={"Department Name"} />
          <MDInput onChange={handleChange} required={true} />
        </Grid>
      </Grid>
    </SubmitForm>
  );
};

export default AddDepartments;
