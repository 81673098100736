import React, { useContext, useEffect, useState } from "react";
import { DatePicker, TimePicker } from "../../components/datePicker";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import Button from "@mui/material/Button";
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid from "@mui/material/Grid";
import { Icon, Paper } from "@mui/material";
import Card from "@mui/material/Card";
import { usersAPI, workingHoursAPI } from "../../assets/apiFunctions";
import { v4 as uuidv4 } from "uuid";
import { setLoader, setNotification, useMaterialUIController } from "../../context";
import messageNotification from "../../assets/messageNotification";
import { isArray } from "chart.js/helpers";
import { utils } from "../../assets/assetsFunctions";
import { GetHoursBetweenDates } from "./components/getHoursBetweenDates";
import { getGlobalContext } from "../../context/globalContext";
import LayoutHeader from "../../components/layoutHeader/layoutHeader";

const date = moment();
const WorkHours = (props) => {
  const initial = {
    hours: [{ id: uuidv4(), from: "", to: "" }],
  };
  const [refresh, setRefresh] = useState(0);
  const [total, setTotal] = useState(0);
  const [controller, dispatch] = useMaterialUIController();
  const { roles } = controller;
  const { users, setUsers } = getGlobalContext();
  const [selectedUser, setSelectedUser] = useState([]);
  const [day, setDay] = useState(date); //should the day be seperated
  const [workingTimes, setWorkingTimes] = useState(initial);

  function edit() {
    setTotal(0);
    let totalTime = utils.calculateHours(workingTimes.hours);
    setTotal(totalTime);
    return totalTime;
  }
  function handleTimeInput(name, value, index) {
    let time = moment(value._d).format("HH:mm");
    const arr = workingTimes.hours;
    arr[index] = { ...arr[index], [name]: time };
    setWorkingTimes({ ...workingTimes, hours: arr });
    setRefresh((refresh) => refresh + 1);
  }
  useEffect(() => {}, [refresh]);
  function greeting() {
    let time = moment().hour();
    if (time < 12) {
      return "Schönen Tag!";
    } else if (time < 18) {
      return "Schönen Nachmittag!";
    } else {
      return "Schönen Abend!";
    }
  }
  async function t() {
    let text = `Hallo stunden von ${day} \r`;
    let times = [];
    let mints = await edit();

    workingTimes?.hours.map((e) => {
      if (e?.from && e?.to) times.push({ from: e?.from, to: e?.to });
    });
    text += times.map((e) => e?.from + "-" + e?.to + "\r");
    text += `Ing ${utils.minutes_to_hhmm(mints)}`;
    text += "\n" + greeting();
    return text;
  }
  function copyText() {
    t().then(
      (res) =>
        (window.location.href = `https://api.whatsapp.com/send?phone=+49 172 5650224&text=${res}`)
    );
    // t().then((res) => navigator.clipboard.writeText(res));
  }
  function checkFromMinTime(index) {
    // check if to time is not before the from time
    if (index == 0) {
      return null;
    }
    if (index > 0) {
      let from = workingTimes.hours[index - 1]?.to;
      from = from.split(":");
      return moment().set({ hours: from[0], minutes: from[1] });
    }
  }
  async function addWarning(s) {
    if (isArray(s)) {
      const updatedHours = [...workingTimes.hours]; // Create a copy of the hours array
      s.map((x) => {
        const index = updatedHours.findIndex((h) => h.id === x?.record_id);
        if (index !== -1) {
          updatedHours[index] = {
            ...updatedHours[index],
            error: `This period already exists from ${utils.removeSeconds(
              x.from
            )} to ${utils.removeSeconds(x.to)}for user ${selectedUser
              .filter((r) => r.id === x.user_id)
              .map((n) => n.name)}`,
          };
        }
      });
      setWorkingTimes((prev) => ({
        ...prev,
        hours: updatedHours,
      }));
    }
  }
  async function submit(e) {
    e.preventDefault();
    let letSubmit = false;
    if (workingTimes?.hours.length > 0) {
      workingTimes?.hours.map((e) => {
        if (!e?.from && !e?.to) {
          setNotification(dispatch, messageNotification.FILED_REQUIRED);
          return;
        }
        letSubmit = true;
      });
    }
    if (letSubmit) {
      edit();
      setLoader(dispatch, true);
      const formData = new FormData();
      const data = { ...workingTimes, day: day };
      formData.append("date", JSON.stringify(data));
      formData.append("users", JSON.stringify(selectedUser?.map((user) => user.id)));
      workingHoursAPI
        .sendWorkingHours(formData)
        .then((res) => {
          handleResponse(res);
        })
        .catch((e) => console.log(e))
        .finally(() => setLoader(dispatch, false));
    }
  }
  function handleResponse(res) {
    if (res.data.message == "success" && res?.data?.with_warnings.length == 0) {
      setNotification(dispatch, messageNotification.SUCCESS_OPERATION);
      setWorkingTimes(initial);
      setRefresh((refresh) => refresh + 1);
    } else if (res.data.message == "success" && res?.data?.with_warnings.length > 0) {
      addWarning(res.data.with_warnings);
      setNotification(dispatch, messageNotification.WARNING);
    }
  }
  function deleteHour(index) {
    // Create a copy of the workingTimes state variable.
    const workingTimesCopy = workingTimes;
    //
    // // Use the splice() method to remove the object at the specified index from the hours array in the copy.
    workingTimesCopy.hours.splice(index, 1);
    // // Set the workingTimes state variable to the copy.
    setWorkingTimes(workingTimesCopy);
    setRefresh((refresh) => refresh + 1);
  }
  function selectUser(event) {
    const {
      target: { value },
    } = event;
    setSelectedUser(typeof value === "string" ? value.split(",") : value);
  }
  // useEffect(() => {
  //   if (!users) {
  //     usersAPI.getUsers().then((res) => setUsers(res.data.users));
  //   }
  // }, []);
  return (
    <>
      {/*for admins*/}
      <MDBox pt={6} pb={3}>
        <Card sx={{ marginTop: 2 }}>
          <LayoutHeader title={"Set your working hours"} />
          <MDBox pt={3}></MDBox>
          <Paper sx={{ padding: "20px" }}>
            <Grid container spacing={2} direction={"row"} lg={6}>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  value={day}
                  onChange={(e) =>
                    // setDate(e);
                    setDay(e)
                  }
                />
              </Grid>
              {/*<Grid item xs={12} sm={6}>*/}
              {/*  {users && (*/}
              {/*    <MultipleSelectPlaceholder*/}
              {/*      options={users}*/}
              {/*      handleChange={selectUser}*/}
              {/*      users={selectedUser}*/}
              {/*      // placeHolder={"add user"}*/}
              {/*    />*/}
              {/*  )}*/}
              {/*</Grid>*/}
            </Grid>
            <form onSubmit={submit}>
              <Grid container direction={"row"} lg={6} spacing={1} my={1}>
                {workingTimes?.hours.map((d, index) => (
                  <Grid item key={d.id}>
                    <Grid container spacing={1}>
                      <Grid item xs={5} sm={"auto"}>
                        <TimePicker
                          label={"from"}
                          onChange={(value) => handleTimeInput("from", value, index)}
                          defaultValue={""}
                          minTime={checkFromMinTime(index)}
                          value={(d.from && moment(d.from, "HH:mm")) || null}
                        />
                      </Grid>
                      <Grid item xs={5} sm={"auto"}>
                        <TimePicker
                          label={"To"}
                          onChange={(value) => handleTimeInput("to", value, index)}
                          defaultValue={""}
                          minTime={utils.minTime(d)}
                          value={(d.to && moment(d.to, "HH:mm")) || null}
                        />
                      </Grid>
                      <Grid item xs={2} sm={"auto"}>
                        <IconButton aria-label="delete" onClick={() => deleteHour(index)}>
                          <DeleteIcon color={"error"} />
                        </IconButton>
                      </Grid>
                      <Grid item xs={12}>
                        <MDTypography variant="caption" color="warning" fontWeight={"regular"}>
                          {d?.error && d?.error}
                        </MDTypography>{" "}
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                }}
                mt={2}
                gap={1}
              >
                <Button
                  onClick={() => {
                    edit();
                    setWorkingTimes({
                      ...workingTimes,
                      hours: [...workingTimes.hours, { id: uuidv4(), from: "", to: "" }],
                    });
                  }}
                  variant={"outlined"}
                >
                  <MDTypography
                    variant="h7"
                    fontWeight="medium"
                    textTransform="capitalize"
                    color={"dark"}
                  >
                    Add a Period
                  </MDTypography>
                </Button>
                <Button type={"submit"} variant={"contained"}>
                  <MDTypography variant="h7" fontWeight="medium" color={"white"}>
                    Submit
                  </MDTypography>
                </Button>
                <Button
                  onClick={() => copyText()}
                  variant={"text"}
                  endIcon={<Icon fontSize="small">content_copy</Icon>}
                >
                  <MDTypography variant="h7" fontWeight="medium" color={"secondary"}>
                    send Via Whatsapp
                  </MDTypography>
                </Button>
              </Stack>
            </form>
            <MDTypography py={2} variant="h6" fontWeight="medium" textTransform="capitalize">
              {`Total Hours:${total && utils.minutes_to_hhmm(total)}`}
            </MDTypography>
            {/*{myHours && <WorkingHoursComponent data={myHours} />}*/}
          </Paper>
        </Card>
      </MDBox>
      <GetHoursBetweenDates getPending={true} isUser={true} />
    </>
  );
};

export default WorkHours;
