import moment from "moment";

const GenerateWeekDays = (date) => {
  const startOfWeek = date.clone().startOf("week"); // Get the start of the current week
  const endOfWeek = date.clone().endOf("week"); // Get the end of the current week

  const days = [];
  let current = startOfWeek.clone();

  while (current.isSameOrBefore(endOfWeek)) {
    days.push(current.format("YYYY-MM-DD")); // Format the date as needed
    current.add(1, "days"); // Move to the next day
  }
  return {
    days,
    startOfWeek: moment(startOfWeek).format("YYYY-MM-DD"),
    endOfWeek: moment(endOfWeek).format("YYYY-MM-DD"),
  };
};

export default GenerateWeekDays;

//eslint-disable-next-line
export const GenerateMonthDays=(month)=>{

  const year = 2024;
  const daysInMonth = moment(`${year}-${month}`, "YYYY-MM").daysInMonth();
  const allDays = [];
  for (let day = 1; day <= daysInMonth; day++) {
    allDays.push(moment(`${year}-${month}-${day}`, "YYYY-MM-DD"));
  }

  return {
    allDays,
  };
};
