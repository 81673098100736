import axiosInstance from "../axiosInstance";
import { prefix } from "./prefixsVariables";

export const usersAPI = {
  createUser: async (data) => {
    return await axiosInstance.post(`${prefix.user}/create_or_update_user`, data);
  },
  profile: async () => {
    return await axiosInstance.get(`${prefix.user}/get_user_profile`);
  },
  disableUser: async (data, id) => {
    return await axiosInstance.post(`${prefix.user}/disable_user/${id}`, data);
  },
  deleteUser: async (id) => {
    return await axiosInstance.post(`${prefix.user}/delete_user/${id}`);
  },
  updateUser: async (data, id) => {
    return await axiosInstance.post(`${prefix.user}/create_or_update_user/${id}`, data);
  },
  getUserById: async (id) => {
    return await axiosInstance.get(`${prefix.user}/show_user_details/${id}`);
  },
  getUsers: async () => {
    return await axiosInstance.get(`${prefix.user}/get_user`);
  },
  getUserRoles: async () => {
    return await axiosInstance.get(`${prefix.user}/display_role`);
  },
};
