import React, { useEffect, useState } from "react";
import generateWeekDays, {
  GenerateMonthDays,
} from "../../components/generateWeekDays/generateWeekDays";
import moment from "moment";
import MDBox from "../../components/MDBox";
import Grid from "@mui/material/Grid";
import MDTypography from "../../components/MDTypography";

const Calendar = (props) => {
  const today = moment();
  const month = today.month() + 1;
  const { allDays, startOfWeek, endOfWeek } = GenerateMonthDays(month);
  return (
    <MDBox>
      <Grid container flex flexDirection={"row"}>
        {allDays &&
          allDays.map((e, index) => (
            <Grid item xs={1} key={index}>
              <DayComponent day={e} />
            </Grid>
          ))}
      </Grid>
    </MDBox>
  );
};
export default Calendar;

//eslint-disable-next-line
export const DayComponent = ({ day }) => {
  return (
    <MDBox variant={"gradient"} flexDirection={"column"} border={0.2} p={1}>
      <MDBox>
        <MDBox>
          <MDTypography
            textTransform={"capitalize"}
            variant="caption"
            fontWeight="bold"
            color="text"
            gutterBottom
          >
            {moment(day).format("ddd")}
          </MDTypography>
        </MDBox>
        <MDBox>
          <MDTypography
            textTransform={"capitalize"}
            variant="caption"
            fontWeight="bold"
            color="text"
            gutterBottom
          >
            {moment(day).format("DD")}
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox mt={6}>
        <MDTypography
          textTransform={"uppercase"}
          variant="caption"
          fontWeight="bold"
          color="success"
          gutterBottom
        >
          100$
        </MDTypography>
      </MDBox>
    </MDBox>
  );
};
