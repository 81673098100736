import React, { useState } from "react";
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import PropTypes from "prop-types";
import useFormData from "../../hooks/useFormData";
import { emailsApi } from "../../assets/prefixApis/emailsApi";
import { contentLoader as ContentLoader } from "../../components/loader/loader";
import MDTypography from "../../components/MDTypography";
import CircularProgress from "@mui/material/CircularProgress";

const ResendConfirmationEmail = ({ status }) => {
  const { with_status } = status;
  const [loadingButton, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  function resendEmail() {
    setMessage("");
    setLoading(true);
    //this function resend an email to the pending reservations
    const formData = useFormData({ name: "reservationId", data: status.id });
    emailsApi
      .rSendConfRes(formData)
      .then((res) => setMessage(res.data.message))
      .finally(() => setLoading(false));
  }
  return (
    <MDBox title={"Resend confirmation email to the guest"}>
      <MDTypography>{with_status.with_type?.name}</MDTypography>
      <MDButton
        color={with_status.with_type.color}
        size={"small"}
        onClick={resendEmail}
        disabled={loadingButton}
      >
        <MDBox display={"flex"} flexDirection={"row"}>
          <MDTypography>Resend</MDTypography>
          {loadingButton && (
            <span style={{ width: 20 }}>
              <ContentLoader />
            </span>
          )}
        </MDBox>
      </MDButton>
      <MDTypography>{message}</MDTypography>
    </MDBox>
  );
};
export default ResendConfirmationEmail;

ResendConfirmationEmail.propTypes = {
  status: PropTypes.object,
};
