import React, { useEffect, useState } from "react";
import SubmitForm from "../../components/forms/submitForm";
import inputs from "./roomInputs";
import { roomsAPI } from "../../assets/apiFunctions";
import UseFormData from "../../hooks/useFormData";
import { setLoader, useMaterialUIController } from "../../context";
import { SERVER } from "../../assets/axiosInstance";
//eslint-disable-next-line
const Add = ({ roomToUpdate }) => {
  const [room, setRoom] = useState({});
  const [controller, dispatch] = useMaterialUIController();

  function submit(e) {
    e.preventDefault();
    setLoader(dispatch, true);
    const formData = UseFormData([{ name: "room", data: room }]);
    roomsAPI
      .setRoom(formData)
      .then((res) => {
        setRoom({});
        setLoader(dispatch, false);
      })
      .catch((e) => setLoader(dispatch, false));
  }
  function handleChange(e) {
    if (e !== null) {
      setRoom({ ...room, [e.target.name]: e.target.value });
    }
  }
  useEffect(() => {
    if (roomToUpdate) {
      let d = roomToUpdate[0];
      setRoom(d);
    }
  }, [roomToUpdate]);
  return (
    <div>
      <SubmitForm user={room} submit={submit} handleChange={handleChange} inputs={inputs} />
    </div>
  );
};
export default Add;
