import React from "react";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import Card from "@mui/material/Card";
import ShiftPlannerComponent from "../../components/shiftPlanner/shiftPlanner";
import LayoutHeader from "../../components/layoutHeader/layoutHeader";

const ShiftPlanner = (props) => {
  return (
    <Card sx={{ marginTop: 2 }}>
      <LayoutHeader title={"Set Users Shift plan"} />
      <MDBox pt={3}></MDBox>
      <MDBox p={2} sx={{ overflow: "scroll" }}>
        <ShiftPlannerComponent />
      </MDBox>
    </Card>
  );
};

export default ShiftPlanner;
