import * as React from "react";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import PropTypes from "prop-types";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// const names = [
//   "Oliver Hansen",
//   "Van Henry",
//   "April Tucker",
//   "Ralph Hubbard",
//   "Omar Alexander",
//   "Carlos Abbott",
//   "Miriam Wagner",
//   "Bradley Wilkerson",
//   "Virginia Andrews",
//   "Kelly Snyder",
// ];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelectPlaceholder({ options, handleChange, users, placeHolder }) {
  const theme = useTheme();
  return (
    <Select
      sx={{
        width: "100%",
        display: "flex",
        "& .css-1cohrqd-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select":
          {
            padding: "0.7rem !important", // Reset padding to default value
          },
      }}
      multiple
      displayEmpty
      value={users}
      onChange={handleChange}
      input={<OutlinedInput />}
      renderValue={(selected) => {
        if (selected.length === 0) {
          return <em>Select users</em>;
        }
        return selected.map((s) => s.name).join(", ");
      }}
      MenuProps={MenuProps}
      inputProps={{ "aria-label": "Without label" }}
    >
      <MenuItem disabled value="">
        <em>{placeHolder}</em>
      </MenuItem>
      {options?.map((name) => (
        <MenuItem key={name.id} value={name} style={getStyles(name.name, users, theme)}>
          {name.name}
        </MenuItem>
      ))}
    </Select>
  );
}
MultipleSelectPlaceholder.defaultProps = {
  placeHolder: "Place holder",
};
MultipleSelectPlaceholder.propTypes = {
  options: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  users: PropTypes.any.isRequired,
  placeHolder: PropTypes.string,
};
