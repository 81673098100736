import React from "react";
import MDBox from "../../components/MDBox";
import Card from "@mui/material/Card";
import MDTypography from "../../components/MDTypography";
import ChangePasswordComponent from "../../components/changePasswordComponent/changePasswordComponent";
import LayoutHeader from "../../components/layoutHeader/layoutHeader";

const ChangePassword = (props) => {
  return (
    <MDBox pt={6} pb={3}>
      <Card sx={{ marginTop: 2 }}>
        <LayoutHeader title={"Change your password"} />
        <MDBox pt={3}></MDBox>
        <ChangePasswordComponent />
      </Card>
    </MDBox>
  );
};

export default ChangePassword;
