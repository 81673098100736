import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { otherAPI, usersAPI } from "../assets/apiFunctions";
import { getClientInfo } from "../assets/assetsFunctions";

export const GlobalContext = React.createContext(null);

const GlobalContextProvider = (props) => {
  const [users, setUsers] = React.useState();
  const mounted = React.useRef(true); // render one time
  // useEffect(() => {
  //   const x = () => {
  //     mounted.current = false;
  //   };
  //
  //   if (mounted.current) {
  //     // const browserFingerPrint = window.cookie
  //     // document.cookie = '<cookieName>=; Max-Age=0;secure'
  //     n();
  //   }
  //
  //   return () => x();
  // }, []);
  //
  // async function n() {
  //   otherAPI
  //     .setVisitor("asdasdsad", await getClientInfo())
  //     .then((res) => {
  //       return null;
  //     })
  //     .catch((e) => console.log("this", e));
  // }
  return (
    <GlobalContext.Provider value={{ users, setUsers }}>{props.children}</GlobalContext.Provider>
  );
};
GlobalContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
export default GlobalContextProvider;

export const getGlobalContext = () => {
  return useContext(GlobalContext);
};
