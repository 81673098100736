/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import MDTypography from "../../components/MDTypography";
import MDBox from "../../components/MDBox";
import AlertDialog from "../../components/dialog/dialog";
import DataTable from "../../examples/Tables/DataTable";
import { departmentAPI } from "../../assets/apiFunctions";
import { useDepartmentContext } from "../../context/departmentContext";
import DepartmentViewOrUpdate from "./components/departmentViewOrUpdate";
import ConditionalRenderer from "../../components/ConditionalRenderer/conditionalRenderer";

const View = (props) => {
  const { departments, isLoading } = useDepartmentContext();

  const Action = ({ action, id }) => (
    <MDBox display={"flex"} gap={1} alignItems={"center"}>
      <AlertDialog
        // onAgree={(e) => handleDelete(id)}
        id={id}
        triggerText={"Delete"}
        title={"name"}
        disagreeText={"Cancel"}
        content={`Are you sure you want to delete " ${name}"?`}
        agreeText={"delete"}
      />
      <MDTypography
        variant="caption"
        color="text"
        fontWeight="medium"
        // onClick={() => navigate(`/edit_user?id=${id}`)}
      >
        {action}
      </MDTypography>
    </MDBox>
  );
  const rows =
    departments &&
    departments.length > 0 &&
    departments?.map((d) => ({
      name: <DepartmentViewOrUpdate department={d} />,
      // action: <Action action="Edit" id={d.id} />,
    }));
  const columns = [
    { Header: "Department", accessor: "name", width: "100%", minWidth: "100%" },
    // { Header: "Action", accessor: "action", align: "center" },
  ];
  return (
    <ConditionalRenderer
      isLoading={isLoading}
      data={rows}
      component={
        <DataTable
          table={{ rows, columns }}
          isSorted={true}
          canSearch={false}
          entriesPerPage={false}
          showTotalEntries={false}
          noEndBorder
        />
      }
    />
  );
};

export default View;
