import React, { useEffect, useRef, useState } from "react";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import Box from "@mui/material/Box";
import MDInput from "../../../components/MDInput";
import SubmitForm from "../../../components/forms/submitForm";
import PropTypes from "prop-types";
import bgImage from "../../../assets/images/bg-reset-cover.jpeg";
import CoverLayout from "../components/CoverLayout";
import Card from "@mui/material/Card";
import { Link } from "react-router-dom";
import { utils } from "../../../assets/assetsFunctions";
import { authAPI } from "../../../assets/apiFunctions";
import MDAlert from "../../../components/MDAlert";
import { useMaterialUIController, setNotification } from "../../../context";
import messageNotification from "../../../assets/messageNotification";
import InputComponent from "../../../components/inputsComponent/inputsComponent";
import Grid from "@mui/material/Grid";
const inputs = {
  name: "newPassword",
  label: "New password",
  value: "newPassword",
  onChange: "handleChange",
  required: true,
  type: "password",
  inputType: "input",
};
export default function NewPassword() {
  const { numberToArray, returnNumberOrNull } = utils;
  const codeArray = ["", "", "", "", "", ""];
  const initPassword = { newPassword: "" };
  const inputsRef = useRef();
  const [codes, setCodes] = useState(codeArray);
  const [controller, dispatch] = useMaterialUIController();
  const [newPassword, setNewPassword] = useState(initPassword);
  const handleChange = async (event, index) => {
    if (event.target.name === "newPassword") {
      setNewPassword({ ...newPassword, newPassword: event.target.value });
    } else {
      const newCodes = [...codes];
      newCodes[index] = await returnNumberOrNull(event.target.value);
      setCodes(newCodes);
      if (inputsRef) {
        const allInputs = inputsRef?.current?.querySelectorAll("input");
        const nextElement = allInputs[index + 1];
        nextElement?.focus();
      }
    }
  };

  const submit = (e) => {
    e.preventDefault();
    const resultNumber = parseInt(codes.join(""), 10);
    const formData = new FormData();
    formData.append("code", resultNumber);
    formData.append("password", newPassword.newPassword);
    authAPI
      .sendCheckOTPCode(formData)
      .then((res) => {
        setNotification(dispatch, messageNotification.SUCCESS_CONFIRMATION);
        setNewPassword(initPassword);
        setCodes(codeArray);
      })
      .catch((e) => setNotification(dispatch, messageNotification.FAILED_CONFIRMATION));
  };

  useEffect(() => {
    // Get the search portion of the URL
    const search = window.location.search;

    // Create a URLSearchParams object from the search string
    const searchParams = new URLSearchParams(search);

    // Get the value of "param1"
    const code = searchParams.get("code");
    if (code !== null && code.length == 6) {
      setCodes(numberToArray(code)); // Log the value to the console
    } else {
      return;
    }
  }, []);

  return (
    <CoverLayout coverHeight="50vh" image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgcolor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Creat new password
          </MDTypography>
        </MDBox>
        <MDTypography
          textTransform={"capitalize"}
          variant="button"
          fontWeight="bold"
          color="text"
          mx={3}
          mt={2}
        >
          Enter the code
        </MDTypography>
        <Box ref={inputsRef} display={"flex"} justifyContent={"center"}>
          {codes?.map((code, index) => (
            <MDInput
              key={index}
              type="text"
              variant="outlined"
              value={codes[index]}
              fullWidth
              onChange={(e) => handleChange(e, index)}
              style={{ marginRight: "8px", width: "12%", height: "27%" }}
              inputProps={{ maxLength: 1 }} // Limit each input to a single character
            />
          ))}
        </Box>
        <SubmitForm
          sm={12}
          lg={12}
          // inputs={inputs}
          handleChange={handleChange}
          user={newPassword}
          submit={submit}
        >
          <Grid item xs={12}>
            <InputComponent e={inputs} handleChange={handleChange} />
          </Grid>
        </SubmitForm>
      </Card>
      <MDBox mt={3} mb={1} textAlign="center">
        <MDTypography variant="button" color="text">
          Back to sign in?{" "}
          <MDTypography
            component={Link}
            to="/authentication/sign-in"
            variant="button"
            color="info"
            fontWeight="medium"
            textGradient
          >
            Sign In
          </MDTypography>
        </MDTypography>
      </MDBox>
    </CoverLayout>
  );
}
