import { useNavigate } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-reset-cover.jpeg";
import React, { useState } from "react";
import { authAPI } from "../../../../assets/apiFunctions";
import { Link } from "react-router-dom";

function Cover() {
  const navigate = useNavigate();
  const [email, setEmail] = useState(null);
  const [disable, setDisable] = useState(false);

  const setDisabledTime = () => {
    setTimeout(() => {
      setDisable(false);
    }, 60 * 1000);
  };
  const emailInput = (e) => {
    setEmail(e.target.value);
  };
  const sendReset = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("email", email);
    email &&
      authAPI
        .sendResetPasswordRequest(formData)
        .then((res) => {
          setDisable(true);
          setDisabledTime();
          navigate("/authentication/confirm-code");
        })
        .catch((e) => console.log(e));
  };

  return (
    <CoverLayout coverHeight="50vh" image={bgImage}>
      <Card>
        <>
          <MDBox
            variant="gradient"
            bgcolor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            py={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
              Reset Password
            </MDTypography>
            <MDTypography display="block" variant="button" color="white" my={1}>
              You will receive an e-mail in maximum 60 seconds
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3} component="form" role="form" onSubmit={sendReset}>
            <MDBox>
              <MDBox mb={4}>
                <MDInput
                  type="email"
                  label="Email"
                  variant="standard"
                  fullWidth
                  required={true}
                  onChange={emailInput}
                />
              </MDBox>
              <MDBox mt={6} mb={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  type={"submit"}
                  disabled={disable}
                >
                  reset
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </>
      </Card>
      <MDBox mt={3} mb={1} textAlign="center">
        <MDTypography variant="button" color="text">
          Already have an account?{" "}
          <MDTypography
            component={Link}
            to="/authentication/sign-in"
            variant="button"
            color="info"
            fontWeight="medium"
            textGradient
          >
            Sign In
          </MDTypography>
        </MDTypography>
      </MDBox>
    </CoverLayout>
  );
}

export default Cover;
