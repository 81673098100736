import React, { useEffect, useState } from "react";
import { reservationsAPI } from "../../assets/apiFunctions";
import ReservationTables from "./reservationTables";
import TabComponent from "../../components/tabsComponent/tabComponent";
import useFormData from "../../hooks/useFormData";
import LayoutHeader from "../../components/layoutHeader/layoutHeader";
import Card from "@mui/material/Card";
import MDBox from "@mui/material/Box";
import ConditionalRenderer from "../../components/ConditionalRenderer/conditionalRenderer";

/**
 * reservation index page
 * */
const Index = (props) => {
  const tabData = [
    { label: "In-house", icon: "pending", color: "warning" },
    { label: "check_in", icon: "check", color: "success" },
    { label: "check_out", icon: "delete", color: "error" },
    { label: "arrivals", icon: "delete", color: "error" },
  ];
  const [reservations, setReservations] = useState(null);
  const [type, setType] = useState(tabData[0].label);
  const [loading, setLoading] = useState(true);

  function onTabChange(e) {
    const x = e.toLocaleLowerCase();
    setType(x);
  }
  function getData() {
    setLoading(true);
    const formData = useFormData({ name: "reservation_type", data: type });
    reservationsAPI
      .getReservations(formData)
      .then((res) => setReservations(res.data.data))
      .finally(() => setLoading(false));
  }
  useEffect(() => {
    getData();
  }, [type]);
  return (
    <div>
      <MDBox pt={6} pb={3}>
        <Card sx={{ marginTop: 2 }}>
          <LayoutHeader title={"Reservations"} />
          <MDBox py={1} px={2}>
            <TabComponent onChange={onTabChange} tabsData={tabData} />
          </MDBox>
          <ConditionalRenderer
            data={reservations}
            isLoading={loading}
            component={<ReservationTables data={reservations} />}
          />
        </Card>
      </MDBox>
    </div>
  );
};

export default Index;
