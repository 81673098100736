/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
import React, { useEffect, useState } from "react";
import MDBox from "../../components/MDBox";
import MDAvatar from "../../components/MDAvatar";
import MDTypography from "../../components/MDTypography";
import MDBadge from "../../components/MDBadge";
import { Button, Icon } from "@mui/material";
import { openGoogleMaps } from "../../assets/assetsFunctions";

const CarsTables = (cars, navigate) => {
  const Type = ({ name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      {/*<MDAvatar src={team2} name={name} size="sm" />*/}
      <MDBox lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
  const PlateNo = ({ title }) => (
    <MDBox lineHeight={1} textAlign="left" display={"flex"} columnGap={5}>
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
    </MDBox>
  );
  const Action = ({ action, id }) => (
    <MDTypography
      variant="caption"
      color="text"
      fontWeight="medium"
      onClick={() => navigate(`/edit/${id}`)}
    >
      {action}
    </MDTypography>
  );

  const rows =
    cars &&
    cars !== "undefined" &&
    cars.map((car) => ({
      type: <Type name={car.type} />,
      plateNo: <PlateNo title={car?.license_plate_number} />,
      action: <Action action="Edit" id={car.id} />,
    }));

  const columns = [
    { Header: "#", accessor: "index", width: "4%", align: "left" },
    { Header: "Type", accessor: "type", align: "left" },
    { Header: "No", accessor: "plateNo", align: "left" },
    // { Header: "Phone", accessor: "phone", align: "left" },
    // { Header: "Address", accessor: "address", align: "center" },
    // { Header: "Employed", accessor: "employed", align: "center" },
    { Header: "Action", accessor: "action", align: "center" },
  ];

  return {
    columns,
    rows,
  };
};

export default CarsTables;
