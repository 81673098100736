import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker as DateMui, MobileTimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import MDTypography from "./MDTypography";
import PropTypes from "prop-types";

const DatePicker = ({ onChange, value, ...rest }) => {
  const date = moment(value || new Date());
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DateMui
        // defaultValue={date}
        onChange={(selected) => onChange(selected)}
        value={date}
        format="DD/MM/YYYY"
        sx={{ width: "100%" }}
        {...rest}
      />
    </LocalizationProvider>
  );
};
const TimePicker = ({ label, onChange, defaultValue, ...reset }) => {
  const time = moment();
  let hours = time;

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      {typeof hours && (
        <MobileTimePicker
          // defaultValue={
          //   typeof defaultValue !== undefined &&
          //   moment().set({ hours: defaultValue[0] + defaultValue[1], minutes: 50 })
          // }
          ampm={false}
          label={label}
          onAccept={onChange}
          {...reset}
        />
      )}

      {/*<TimeMui ampm={false} defaultValue={moment()} label={label} />*/}
    </LocalizationProvider>
  );
};
TimePicker.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.any,
  value: PropTypes.any,
};
DatePicker.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
};

export { DatePicker, TimePicker };
