/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav.
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  3. The `type` hide to prevent render the route inside side nav.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import WorkHours from "layouts/workHours";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import Users from "layouts/users";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import ResetCover from "layouts/authentication/reset-password/cover";
import Rooms from "layouts/rooms";
import RoomTypes from "layouts/roomTypes";
import RoomRates from "layouts/ratesAvailability";
import Edit from "layouts/roomTypes/edit";

// @mui icons
import Icon from "@mui/material/Icon";
import EditUser from "./layouts/users/edit";
import Patients from "./layouts/patients";
import ForgetPassword from "./layouts/forgetPassword/forgetPassword";
import Cars from "./layouts/cars";
import NewPassword from "./layouts/authentication/reset-password/confirmCode";
import WorkHoursReport from "./layouts/reports/workHoursReport/workHoursReport";
import { PatientContextProvider } from "./context/patientContext";
import WorkingHoursProvider from "./context/workingHoursContext";
import UsersContextProvider from "./context/usersContext";
import CheckUsersWorkingHours from "./layouts/workHours/checkUsersWhours/checkUsersWorkingHours";
import EditPatient from "./layouts/patients/edit";
import React from "react";
import ShiftPlanner from "./layouts/shiftPlanner/shiftPlanner";
import ChangePasswordComponent from "./components/changePasswordComponent/changePasswordComponent";
import ChangePassword from "./layouts/changePassword/changePassword";
import Shift from "./layouts/shift/shift";
import Department from "./layouts/department";
import Reservations from "./layouts/reservations";
import ReservationDetails from "./layouts/reservations/reservationDetails";
import EditRoom from "./layouts/rooms/edit";

const routes = [
  {
    type: "collapse",
    auth: [1000, 3000],
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    auth: [1000, 3000],
    name: "Work Hours",
    key: "work-hours",
    icon: <Icon fontSize="small">schedule</Icon>,
    route: "/work-hours",
    component: (
      <WorkingHoursProvider>
        <WorkHours />
      </WorkingHoursProvider>
    ),
  },
  {
    type: "divider",
    key: "divider",
  },
  {
    type: "hide",
    name: "editUsers",
    auth: [1000, 3000],
    key: "editusers",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/edit_user",
    component: <EditUser />,
  },
  {
    type: "collapse",
    name: "Users",
    auth: [3000],
    key: "users",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/users",
    component: (
      <UsersContextProvider>
        <Users />
      </UsersContextProvider>
    ),
  },
  {
    type: "collapse",
    name: "department",
    auth: [3000],
    key: "department",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/department",
    component: <Department />,
  },
  {
    type: "divider",
    key: "divider2",
  },
  {
    type: "hide",
    name: "patients",
    auth: [1000, 3000],
    key: "patients",
    icon: <Icon fontSize="small">elderly</Icon>,
    route: "/patients",
    component: (
      <PatientContextProvider>
        <Patients />
      </PatientContextProvider>
    ),
  },
  {
    type: "hide",
    name: "Tables",
    auth: [1000, 3000],
    key: "tables",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/tables",
    component: <Tables />,
  },
  {
    type: "hide",
    name: "Billing",
    auth: [2000],
    key: "billing",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/billing",
    component: <Billing />,
  },
  {
    type: "hide",
    name: "RTL",
    auth: [2000],
    key: "rtl",
    icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
    route: "/rtl",
    component: <RTL />,
  },
  {
    type: "collapse",
    name: "Cars",
    auth: [1000, 3000],
    key: "car",
    public: false,
    icon: <Icon fontSize="small">directions_car</Icon>,
    route: "/car",
    component: <Cars />,
  },
  {
    type: "collapse",
    name: "shitfs",
    auth: [3000],
    key: "shitfs",
    public: false,
    icon: <Icon fontSize="small">directions_car</Icon>,
    route: "/shitfs",
    component: <Shift />,
  },
  {
    type: "hide",
    name: "Notifications",
    key: "notifications",
    auth: [2000],
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: <Notifications />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    auth: [1000, 3000],
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  {
    type: "hide",
    name: "edit_patient",
    key: "edit_patient",
    auth: [1000, 3000],
    icon: <Icon fontSize="small">person</Icon>,
    route: "/edit_patient",
    component: <EditPatient />,
  },
  {
    type: "collapse",
    name: "Uhr kontrolle",
    key: "check",
    public: false,
    auth: [3000],
    icon: <Icon fontSize="small">person</Icon>,
    route: "/check",
    component: <CheckUsersWorkingHours />,
  },
  {
    type: "hide",
    name: "Change password",
    key: "change_password",
    public: false,
    auth: [3000],
    icon: <Icon fontSize="small">person</Icon>,
    route: "/change_password",
    component: <ChangePassword />,
  },
  {
    type: "collapse",
    name: "shift_planner",
    key: "shift_planner",
    public: false,
    auth: [3000],
    icon: <Icon fontSize="small">person</Icon>,
    route: "/shift_planner",
    component: <ShiftPlanner />,
  },
  {
    type: "collapse",
    name: "Report",
    auth: [1000, 3000],
    key: "work-hours-report",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/work-hours-report",
    component: <WorkHoursReport />,
    public: false,
  },
  {
    type: "collapse",
    name: "Rooms",
    auth: [1000, 3000],
    key: "rooms",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/rooms",
    component: <Rooms />,
    public: false,
  },
  {
    type: "collapse",
    name: "Room Types",
    auth: [1000, 3000],
    key: "roomTypes",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/room-types",
    component: <RoomTypes />,
    public: false,
  },
  {
    type: "collapse",
    name: "rates Types",
    auth: [1000, 3000],
    key: "rates",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/rooms-rates",
    component: <RoomRates />,
    public: false,
  },
  {
    type: "hide",
    name: "Sign In",
    auth: [1000, 3000],
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
    public: true,
  },
  {
    type: "hide",
    name: "Confirm Code",
    key: "confirm-code",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/confirm-code",
    component: <NewPassword />,
    public: true,
  },
  {
    type: "hide",
    name: "Sign Up",
    auth: [1000, 3000],
    key: "sign-up",
    public: true,
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
  {
    type: "hide",
    name: "Sign Up",
    key: "reset",
    auth: [1000, 3000],
    public: true,
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/reset",
    component: <ResetCover />,
  },
  {
    type: "hide",
    name: "Edit Room Type",
    key: "room_type_edit",
    auth: [1000, 3000],
    public: false,
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/room-types/edit",
    component: <Edit />,
  },
  {
    type: "hide",
    name: "Edit Room Type",
    key: "room_type_edit",
    auth: [1000, 3000],
    public: false,
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/rooms/edit",
    component: <EditRoom />,
  },
  {
    type: "collapse",
    name: "reservations",
    key: "reservations",
    auth: [1000, 3000],
    public: false,
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/reservations",
    component: <Reservations />,
  },
  {
    type: "hide",
    name: "reservations",
    key: "reservations",
    auth: [1000, 3000],
    public: false,
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/reservation_details",
    component: <ReservationDetails />,
  },
];

export default routes;
