import React from "react";
import { useMaterialUIController } from "../context";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const IgnorNavigateToSign = (props) => {
  const [controller] = useMaterialUIController();
  const { userName } = controller;
  const list = ["sign-in", "reset", "sign-up"];
  const location = useLocation();
  return userName && list.some((item) => location.pathname.includes(item)) ? (
    <Navigate to={"/"} />
  ) : (
    <Outlet />
  );
};

export default IgnorNavigateToSign;
