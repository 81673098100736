import axiosInstance from "../axiosInstance";
import { prefix } from "./prefixsVariables";

export const emailsApi = {
  rSendConfRes: async (data) => {
    return await axiosInstance.post(`${prefix.emails}/resend_email`, data);
  },
  getReservationById: async (id) => {
    return await axiosInstance.get(`${prefix.reservations}/get_reservation_by_id/${id}`);
  },
};
