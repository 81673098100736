import React, { createContext, useContext, useEffect, useState } from "react";
import moment from "moment";
import useFormData from "../hooks/useFormData";
import { roomsAPI } from "../assets/apiFunctions";

const RatesAvailability = createContext(null);
//eslint-disable-next-line
export const RatesAvailabilityContextProvider = ({ children }) => {
  const today = moment();
  const [date, setDate] = React.useState({ startDate: today, endDate: today });
  const [data, setData] = useState({});
  function handleChange(e) {
    setData({ ...data, [e.target.name]: e.target.value });
  }
  function handleChangeDate(name, value) {
    setDate({ ...date, [name]: value });
  }
  function submit(e) {
    e.preventDefault();
    const formData = useFormData({ name: "data", data: { ...data, date: date } });
    roomsAPI.setRatesAvailability(formData).then((res) => console.log(res));
  }

  return (
    <RatesAvailability.Provider value={{ handleChange, handleChangeDate, date, data, submit }}>
      {children}
    </RatesAvailability.Provider>
  );
};
export const getRatesAvailabilityVariables = () => {
  return useContext(RatesAvailability);
};
