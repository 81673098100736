/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { utils } from "../../../assets/assetsFunctions";

function Patient({ firstName, id, lastName, phone, gender, address, noGutter }) {
  return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      py={1}
      pr={1}
      mb={noGutter ? 0 : 1}
    >
      <MDBox lineHeight={1.125}>
        <MDTypography variant="button" fontWeight="regular" color="text">
          {gender}
        </MDTypography>
        <MDBox display="flex" alignItems="center" gap={1}>
          <MDTypography
            display="block"
            variant="button"
            fontWeight="medium"
            textTransform={"capitalize"}
          >
            {lastName}
          </MDTypography>
          <MDTypography
            display="block"
            variant="button"
            fontWeight="light"
            textTransform={"capitalize"}
          >
            {firstName}
          </MDTypography>
        </MDBox>
        <MDBox display="flex" alignItems="center" gap={1}>
          <MDTypography variant="button" display="block" fontWeight="regular">
            {address[0]?.street_address}
          </MDTypography>
          {","}
          <MDTypography variant="button" display="block" fontWeight="regular">
            {address[0]?.city}
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox
        display="flex"
        alignItems="center"
        onClick={() =>
          utils.openGoogleMaps(`${address[0].street_address},${address[0].plz} ${address[0].city}`)
        }
      >
        <MDBox display="flex" alignItems="center" lineHeight={1} ml={3} sx={{ cursor: "pointer" }}>
          <Icon fontSize="large" color={"error"}>
            location_on
          </Icon>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of Invoice
Patient.defaultProps = {
  noGutter: false,
};

// Typechecking props for the Invoice
Patient.propTypes = {
  id: PropTypes.string.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  gender: PropTypes.string,
  address: PropTypes.array,
  phone: PropTypes.number,
  noGutter: PropTypes.bool,
};

export default Patient;
