import axios from "axios";

export const SERVER = "https://srobh.rawad.net/";
export const SERVER_IMAGES = "https://srobh.rawad.net/images";
/**
 * make it async await when user redirect to specific route after login
 * will need await to get the token from local storage
 **/
async function getToken() {
  return localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : null;
}
async function createHeaders() {
  const token = await getToken();
  const headers = {
    Authorization: token ? `Bearer ${token}` : undefined,
    "Content-Type": "application/json",
  };
  return headers;
}
const axiosInstance = axios.create({
  baseURL: SERVER,
  timeout: 6000,
  headers: createHeaders(),
});

axiosInstance.interceptors.response.use(function (res) {
  if (res.headers.get("roles")) {
    localStorage.setItem("roles", res.headers.get("roles"));
  }
  return res;
});
axiosInstance.interceptors.request.use(async function (conf) {
  if (!conf?.sent) {
    if (conf.headers.Authorization == null || conf.headers.Authorization == undefined) {
      conf.headers.Authorization = await getToken();
    }
  }
  conf.sent = true;
  return conf;
});
export default axiosInstance;
