import axiosInstance from "../axiosInstance";
import { prefix } from "./prefixsVariables";

export const patientsAPI = {
  createPatient: async (data) => {
    return await axiosInstance.post(`${prefix.patient}/create_patient`, data);
  },
  getPatients: async (data) => {
    return await axiosInstance.get(`${prefix.patient}/get_patient`, data);
  },
  getPatientById: async (id) => {
    return await axiosInstance.get(`${prefix.patient}/get_patient_by_id/${id}`);
  },
  deletePatient: async (id) => {
    return await axiosInstance.post(`${prefix.patient}/delete_patient/${id}`);
  },
  updatePatient: async (data, id) => {
    return await axiosInstance.post(`${prefix.patient}/update_patient/${id}`, data);
  },
};
