const commonProps = {
  onChange: "handleChange",
  required: false,
  type: "number",
  inputType: "input",
};

const patientDetailsFields = [
  {
    ...commonProps,
    name: "first_name",
    label: "First Name",
    value: "name",
    required: true,
    type: "text",
  },
  {
    ...commonProps,
    name: "last_name",
    label: "Last Name",
    value: "lastName",
    required: true,
    type: "text",
  },
  {
    ...commonProps,
    name: "phone",
    label: "Phone",
    value: "phone",
  },
];

const patientAddressFields = [
  {
    ...commonProps,
    name: "street_address",
    label: "Street Address",
    value: "street_address",
    required: false,
    type: "text",
    inputType: "combobox",
  },
  {
    ...commonProps,
    name: "plz",
    label: "Postal Code",
    value: "plz",
    required: false,
    type: "number",
  },
  {
    ...commonProps,
    name: "city",
    label: "City",
    value: "city",
    required: false,
    type: "text",
  },
];

const formFields = [
  { name: "Patient Details", fields: patientDetailsFields },
  { name: "Patient Address", fields: patientAddressFields },
];

export default formFields;
