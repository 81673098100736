export default {
  SUCCESS_OPERATION: {
    open: true,
    message: "The operation was successful",
    type: "success",
  },
  FAILED_OPERATION: {
    open: true,
    message: "operation failed",
    type: "error",
  },
  Exist_user: {
    open: true,
    message: "this email is already exist",
    type: "error",
  },
  FAILED_CONFIRMATION: {
    open: true,
    message: "This code is invalid or expired",
    type: "error",
  },
  SUCCESS_CONFIRMATION: {
    open: true,
    message: "Password has been successfully reset",
    type: "success",
  },
  CAR_EXIST: {
    open: true,
    message: "Car already exist",
    type: "error",
  },
  FILED_REQUIRED: {
    open: true,
    message: "All fields are mandatory and must not be left empty.",
    type: "error",
  },
  WARNING: {
    open: true,
    message: "All fields are mandatory and must not be left empty.",
    type: "warning",
  },
};

export const ErrorCodes = {
  409: {
    open: true,
    message: "name is already exist",
    type: "error",
  },
};
