import React, { useEffect, useState } from "react";
import { Icon, Paper } from "@mui/material";
import Grid from "@mui/material/Grid";
import { DatePicker, TimePicker } from "../datePicker";
import moment from "moment/moment";
import MDTypography from "../MDTypography";
import MDButton from "../MDButton";
import Label from "../label/label";
import LayoutHeader from "../layoutHeader/layoutHeader";
import MDInput from "../MDInput";
import MDBox from "../MDBox";

const date = moment();
const AddSickReport = (props) => {
  const initState = {
    from: date,
    to: date,
  };
  const [sickPeriod, setSickPeriod] = useState(initState); //should the day be seperated
  const handleChangeDate = (d, type) => {
    setSickPeriod({ ...sickPeriod, [type]: d });
  };
  useEffect(() => {
    console.log(sickPeriod);
  }, [sickPeriod]);
  return (
    <MDBox>
      <MDBox
        // mx={2}
        mt={-3}
        py={2}
        // px={2}
        display={"flex"}
        justifyContent={"center"}
        variant="gradient"
        bgcolor="dark"
        // borderRadius="lg"
        coloredShadow="dark"
      >
        <MDTypography variant="h6" color="white">
          krank
        </MDTypography>
      </MDBox>
      {/*<LayoutHeader title={"Set your working hours"} />*/}
      <MDBox sx={{ padding: "20px" }} display={"flex"} flexDirection={"column"} gap={3}>
        <Grid container spacing={2} direction={"row"}>
          <Grid item xs={6}>
            <Label name={"from"} />
            <DatePicker
              value={sickPeriod.from}
              onChange={(e) => handleChangeDate(e, "from")}
              minDate={date}
            />
          </Grid>{" "}
          <Grid item xs={6}>
            <Label name={"to"} />
            <DatePicker
              value={sickPeriod.to}
              onChange={(e) => handleChangeDate(e, "to")}
              minDate={date}
            />
          </Grid>
        </Grid>
        <MDInput label="Long Text Input" multiline rows={4} variant="outlined" fullWidth />
        <MDBox>
          <Label name={"upload krank"} />
          <MDInput type={"file"} />
        </MDBox>
        <MDButton sx={{ marginTop: "auto" }} color={"error"}>
          Krankmelden
        </MDButton>
      </MDBox>
    </MDBox>
  );
};

export default AddSickReport;
