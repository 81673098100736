import { createContext, useContext, useEffect, useState } from "react";
import { departmentAPI } from "../assets/apiFunctions";

const DepartmentContext = createContext();
//eslint-disable-next-line
export const DepartmentContextProvider = ({children}) => {
  const [departments, setDepartments] = useState();
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    departmentAPI
      .getDepartmentAPI()
      .then((res) => setDepartments(res.data.data))
      .finally(() => setLoading(false));
  }, []);
  return (
    <DepartmentContext.Provider value={{ departments, setDepartments, isLoading }}>
      {children}
    </DepartmentContext.Provider>
  );
};

export const useDepartmentContext = () => {
  return useContext(DepartmentContext);
};
