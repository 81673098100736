const commonProps = {
  onChange: "handleChange",
};
const group1 = [
  {
    ...commonProps,
    name: "is_closed",
    label: "open",
    value: "is_closed",
    required: true,
    inputType: "radio",
    options: ["open", "close"],
  },
  {
    ...commonProps,
    name: "price",
    label: "Price",
    value: "price",
    required: true,
    type: "number",
    inputType: "input",
  },
  {
    ...commonProps,
    name: "available_rooms_to_sell",
    label: "rooms",
    value: "available_rooms_to_sell",
    required: true,
    type: "number",
    inputType: "input",
  },
  {
    ...commonProps,
    name: "type_id",
    label: "room type",
    value: "type_id",
    required: true,
    inputType: "select",
    options: "roomTypes",
  },
];

const formFields = [{ name: "Rate details", fields: group1 }];

export default formFields;
