import React, { useEffect, useState } from "react";
import MDBox from "../../components/MDBox";
import Card from "@mui/material/Card";
import MDTypography from "../../components/MDTypography";
import inputs from "./carsInputs";
import SubmitForm from "../../components/forms/submitForm";
import { useMaterialUIController, setLoader, setNotification } from "../../context";
import notification from "../../assets/messageNotification";
import { carsAPI } from "../../assets/apiFunctions";
import CarsTables from "./carsTables";
import DataTable from "../../examples/Tables/DataTable";
import { contentLoader } from "../../components/loader/loader";
import UseFormData from "../../hooks/useFormData";
import LayoutHeader from "../../components/layoutHeader/layoutHeader";

const Cars = (props) => {
  const [localLoader, setLocalLoader] = useState(false);
  const carInit = {};
  const [controller, dispatch] = useMaterialUIController();
  const [car, setCar] = useState(carInit);
  const [cars, setCars] = useState();
  const { rows, columns } = CarsTables(cars);

  useEffect(() => {
    setLocalLoader(true);
    carsAPI
      .getAllCars()
      .then((res) => setCars(res.data.data))
      .catch((e) => console.log(e))
      .finally(() => setLocalLoader(false));
  }, []);
  const submit = async (e) => {
    e.preventDefault();
    setLoader(dispatch, true);
    const formData = await UseFormData({ name: "car", data: car });
    carsAPI
      .createCar(formData)
      .then((res) => {
        if (res.data.status === true) {
          setCars([res.data.car, ...cars]);
          setCar(carInit);
          setNotification(dispatch, notification.SUCCESS_OPERATION);
        }
      })
      .catch((e) => {
        if (e?.response?.data.error_code == 122) {
          setNotification(dispatch, notification.CAR_EXIST);
        }
      })
      .finally(() => setLoader(dispatch, false));
  };
  function handleChange(e) {
    setCar({ ...car, [e.target.name]: e.target.value });
  }
  return (
    <>
      <MDBox pt={6} pb={3}>
        <Card sx={{ marginTop: 2 }}>
          <LayoutHeader title={"Add Patient"} />
          <MDBox pt={3}></MDBox>
          <SubmitForm user={car} submit={submit} handleChange={handleChange} inputs={inputs} />
        </Card>
      </MDBox>
      {!localLoader ? (
        rows && cars ? (
          <DataTable
            table={{ columns, rows }}
            isSorted={true}
            entriesPerPage={true}
            showTotalEntries={false}
          />
        ) : (
          <div>No data</div>
        )
      ) : (
        contentLoader()
      )}
    </>
  );
};

export default Cars;
