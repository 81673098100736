import React, { useEffect, useState } from "react";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import Card from "@mui/material/Card";
import { GetHoursBetweenDates } from "../index";
import HoursList from "./hoursList";
import { workingHoursAPI } from "../../../assets/apiFunctions";
import TabComponent from "../../../components/tabsComponent/tabComponent";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import { DatePicker } from "../../../components/datePicker";
import MDButton from "../../../components/MDButton";
import moment from "moment/moment";
import { contentLoader } from "../../../components/loader/loader";
import UseFormData from "../../../hooks/useFormData";
import LayoutHeader from "../../../components/layoutHeader/layoutHeader";

const CheckUsersWorkingHours = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("pending");
  const [status, setStatus] = useState(type);
  const today = moment();
  async function getUsersWorkingHours(date) {
    setLoading(true);
    const startDate = date?.startDate || today;
    const endDate = date?.endDate || today;
    const details = { type: type, startDate: startDate, endDate: endDate };
    const formData = await UseFormData({ name: "details", data: details });
    workingHoursAPI
      .getUsersWorkingHours(formData)
      .then((res) => setData(res.data))
      .finally(() => {
        setLoading(false);
        setStatus(type);
      });
  }
  useEffect(() => {
    getUsersWorkingHours();
  }, []);
  function onTabChange(e) {
    const x = e.toLocaleLowerCase();
    setType(x);
  }
  function onSubmit(s) {
    getUsersWorkingHours(s);
  }
  const tabData = [
    { label: "Pending", icon: "pending", color: "warning" },
    { label: "Accepted", icon: "check", color: "success" },
    { label: "Deleted", icon: "delete", color: "error" },
  ];
  return (
    <MDBox pt={6} pb={3}>
      <Card sx={{ marginTop: 2 }}>
        <LayoutHeader title={"Check users Working hours"} />
        <MDBox py={1} px={2}>
          <TabComponent onChange={onTabChange} tabsData={tabData} />
        </MDBox>
        <MDBox px={2}>
          <Controller submit={onSubmit} today={today} />
        </MDBox>
        {loading ? (
          contentLoader()
        ) : data && data.length !== 0 ? (
          <HoursList profiles={data} status={status} />
        ) : (
          <MDBox py={3} px={2} alignSelf={"center"}>
            <MDTypography variant="h6" color="text">
              No data found
            </MDTypography>
          </MDBox>
        )}
      </Card>
    </MDBox>
  );
};

export default CheckUsersWorkingHours;
//eslint-disable-next-line
const Controller = ({ submit,today }) => {
  const [date, setDate] = React.useState({ startDate: today, endDate: today });

  function handleChangeDate(name, value) {
    setDate({ ...date, [name]: value });
  }
  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
      }}
      mt={2}
      gap={1}
    >
      <Grid item>
        <DatePicker
          value={date.startDate}
          defaultValue={date.startDate}
          onChange={(e) =>
            // setDate(e);
            handleChangeDate("startDate", e)
          }
        />{" "}
      </Grid>
      <Grid item>
        <DatePicker
          defaultValue={date.endDate}
          onChange={(e) =>
            // setDate(e);
            handleChangeDate("endDate", e)
          }
        />
      </Grid>
      <MDButton
        sx={{ marginLeft: "auto", width: { xs: "100%", md: "auto" } }}
        variant={"outlined"}
        color={"info"}
        onClick={() => submit(date)}
      >
        Search
      </MDButton>
    </Stack>
  );
};
