export default [
  {
    name: "Car Details",
    fields: [
      {
        name: "type",
        label: "Car type",
        value: "type",
        onChange: "handleChange",
        required: true,
        type: "text",
        inputType: "input",
      },
      {
        name: "license_plate_number",
        label: "license plate number",
        value: "license_plate_number",
        onChange: "handleChange",
        required: false,
        type: "text",
        inputType: "input",
      },
    ],
  },
];
