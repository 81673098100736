import React, { useState } from "react";
import MDTypography from "../../../components/MDTypography";
import PropTypes from "prop-types";
import DepartmentToUpdate from "./departmentToUpdate";
import MDButton from "../../../components/MDButton";
import MDBox from "../../../components/MDBox";
import { Icon } from "@mui/material";

const DepartmentViewOrUpdate = ({ department }) => {
  const [showInput, setShowInput] = useState(false);
  return (
    <>
      {showInput ? (
        <MDBox>
          <DepartmentToUpdate value={department} changeInputState={() => setShowInput(false)} />
        </MDBox>
      ) : (
        <MDBox
          display={"flex"}
          alignItems={"center"}
          sx={{ minWidth: "100% !important" }}
          justifyContent={"space-between"}
        >
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {department.name}
          </MDTypography>
          <MDButton variant={"text"} size={"medium"} onClick={() => setShowInput(true)}>
            <Icon color={"success"}>edit</Icon>
          </MDButton>{" "}
        </MDBox>
      )}
    </>
  );
};

export default DepartmentViewOrUpdate;
DepartmentViewOrUpdate.propTypes = {
  department: PropTypes.array.isRequired,
};
