import React from "react";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import AlertDialog from "../../../components/dialog/dialog";
import PropTypes from "prop-types";
import { utils } from "../../../assets/assetsFunctions";
import { Icon } from "@mui/material";
import { workingHoursAPI } from "../../../assets/apiFunctions";
import RenderHourComp from "./renderHourComp";

const RenderHours = ({ hours, isAdmin }) => {
  return (
    <MDBox
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      width={"100%"}
      justifyContent="center"
      gap={2}
    >
      {hours &&
        hours
          .sort((a, b) => utils.checkHour(a.from, b.from))
          .map((hour) => (
            <RenderHourComp hour={hour} key={hour.id || hour.working_hour_id} isAdmin={isAdmin} />
          ))}
    </MDBox>
  );
};
export default RenderHours;

RenderHours.propTypes = {
  hours: PropTypes.array.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};
