/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
import React, { useEffect, useId, useState } from "react";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
import moment from "moment/moment";
import DataTable from "../../examples/Tables/DataTable";
import PopupDialog from "../dialog/popupDialog";
import { otherAPI, shiftsApi, usersAPI } from "../../assets/apiFunctions";
import { getUserContextVariables } from "../../context/usersContext";
import { getGlobalContext } from "../../context/globalContext";
import header from "../../layouts/profile/components/Header";
import generateWeekDays from "../generateWeekDays/generateWeekDays";
import { date } from "yup";
import Divider from "@mui/material/Divider";
import PrintableData from "../printTable/printTable";
import ShiftDataTable from "./dataTable";
import { contentLoader } from "../loader/loader";

const ShiftPlannerComponent = () => {
  const today = moment(); // Get the current date
  const [data, setData] = React.useState(false);
  const [users, setUsers] = React.useState(null);
  const [loader, setLoader] = React.useState(false);
  const { days, startOfWeek, endOfWeek } = generateWeekDays(today);

  useEffect(() => {
    setLoader(true);
    shiftsApi.getShifts().then((res) => setData(res.data.shifts));
    shiftsApi
      .getUsersShift({ days: days })
      .then((res) => setUsers(res.data.shifts))
      .finally(() => setLoader(false));
  }, []);

  // const handleShiftChange = (user, day, shift) => {
  //   const updatedUsers = [...users];
  //   updatedUsers[user].shift[day] = shift;
  //   setUsers(updatedUsers);
  // };
  const RenderDay = ({ name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      {/*<MDAvatar src={team2} name={name} size="sm" />*/}
      <MDBox lineHeight={1}>
        <MDTypography display="block" variant="h4" fontWeight="medium" textAlign={"center"}>
          {name}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
  const ShiftPlan = ({ shift, day, user }) => (
    <MDBox display="flex" alignItems="center" justifyItems={"center"} width={"100%"} lineHeight={1}>
      <PopupDialog id={useId()} shift={shift[0]?.shifts[0]} user={user} day={day} data={data} />
    </MDBox>
  );

  const renderHeader = (day) => {
    return (
      <MDBox>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {moment(day).format("dddd")}
        </MDTypography>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {moment(day).format("DD-MM")}
        </MDTypography>
      </MDBox>
    );
  };

  const columns = [
    { Header: "User", accessor: "user", align: "left", isFixed: true },
    ...days
      .map((day) => {
        return { Header: renderHeader(day), accessor: day, align: "left" };
      })
      .flat(),
  ];
  const rows =
    users &&
    users.map((user, index) => {
      const userRow = {
        user: <RenderDay name={user.name} />,
        ...days.reduce((acc, day) => {
          acc[day] = (
            <ShiftPlan user={user.id} day={day} shift={user?.shifts.filter((s) => s.day == day)} />
          );
          return acc;
        }, {}),
      };
      return userRow;
    });
  if (loader) return contentLoader();
  if (!columns || !rows) return null;
  return (
    <>
      {/*{console.log(rows)}*/}
      <ShiftDataTable
        rows={rows}
        columns={columns}
        // table={{ columns, rows }}
        isSorted={false}
        entriesPerPage={false}
        // pagination={false}
        showTotalEntries={false}
        noEndBorder={false}
      />
    </>
  );
};

export default ShiftPlannerComponent;
