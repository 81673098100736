import axiosInstance from "../axiosInstance";

export const authAPI = {
  signIn: async (data) => {
    return await axiosInstance.post(`/api/login`, data);
  },
  sendResetPasswordRequest: async (data) => {
    return await axiosInstance.post(`/api/useForgetPassword`, data);
  },
  sendCheckOTPCode: async (data) => {
    return await axiosInstance.post(`/api/user_reset_password`, data);
  },
};
