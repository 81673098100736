import React from "react";
import MDBox from "../../components/MDBox";
import Card from "@mui/material/Card";
import LayoutHeader from "../../components/layoutHeader/layoutHeader";
import Add from "./add";
import RoomsTypesTablesTable from "./roomsTypesTable";

const Index = (props) => {
  return (
    <div>
      <div>
        <MDBox pt={6} pb={3}>
          <Card sx={{ marginTop: 2 }}>
            <LayoutHeader title={"Rooms Add"} />
            <MDBox pt={3}></MDBox>
            <Add />
          </Card>
          <RoomsTypesTablesTable />
        </MDBox>
      </div>
    </div>
  );
};
export default Index;
