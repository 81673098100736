import React, { useState } from "react";
import MDBox from "../../components/MDBox";
import Card from "@mui/material/Card";
import MDTypography from "../../components/MDTypography";
import PropTypes from "prop-types";
import SubmitForm from "../../components/forms/submitForm";
import inputs from "./patientInputs";
import { Grid } from "@mui/material";
import MdSelect from "../../components/MDRadio/MDRadio";
import { setLoader, setNotification, useMaterialUIController } from "../../context";
import { patientsAPI } from "../../assets/apiFunctions";
import notification from "../../assets/messageNotification";
import UseFormData from "../../hooks/useFormData";
import LayoutHeader from "../../components/layoutHeader/layoutHeader";

const AddPatient = (props) => {
  const patientInit = { gender: "Herr" };
  const [controller, dispatch] = useMaterialUIController();
  const [patient, setPatient] = useState(patientInit);
  const options = ["Herr", "Frau"];
  const submit = async (e) => {
    e.preventDefault();
    setLoader(dispatch, true);
    const formData = await UseFormData({ name: "patient", data: patient });
    patientsAPI
      .createPatient(formData)
      .then((res) => {
        setPatient(patientInit);
        setNotification(dispatch, notification.SUCCESS_OPERATION);
      })
      .catch((e) => setNotification(dispatch, notification.FAILED_OPERATION))
      .finally(() => setLoader(dispatch, false));
  };
  function handleChange(e) {
    if (e !== null) {
      setPatient({ ...patient, [e.target.name]: e.target.value });
    }
  }
  return (
    <MDBox pt={6} pb={3}>
      <Card sx={{ marginTop: 2 }}>
        <LayoutHeader title={"Add Patient"} />
        <MDBox pt={3}></MDBox>
        <SubmitForm user={patient} submit={submit} handleChange={handleChange} inputs={inputs}>
          <Grid item xs={12} sm={4}>
            <MdSelect
              data={patient}
              name={"gender"}
              options={options}
              onChange={handleChange}
              defaultValue={patient?.gender}
              sx={{ m: 2, width: "25ch" }}
            />
          </Grid>
        </SubmitForm>
      </Card>
    </MDBox>
  );
};

export default AddPatient;
AddPatient.propTypes = {
  children: PropTypes.any,
};
