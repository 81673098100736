import React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import MDBox from "@mui/material/Box";
import MDTypography from "@mui/material/Typography";
import PropTypes from "prop-types";
import ComboBox from "../comboBox/comboBox";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { FormControl } from "@mui/material";
import MdSelect from "../MDSelect/MDSelect";
import MDRadio from "../MDRadio/MDRadio";

function InputComponent({ e, user, handleChange }) {
  const renderLabel = () => (
    <InputLabel htmlFor={e?.name}>
      <MDTypography textTransform={"uppercase"} variant="caption" fontWeight="bold" color="text">
        {e?.label}
      </MDTypography>
    </InputLabel>
  );

  switch (e.inputType) {
    case "textArea":
      return (
        <Box sx={{ m: 1 }} justifySelf={"self-start"} xs={12} sm={9} lg={4} key={e.name}>
          {renderLabel()}
          <TextField
            key={e.name}
            // id={e.name}
            id="outlined-multiline-flexible"
            aria-describedby="my-helper-text"
            onChange={handleChange}
            required={e.required}
            value={user && user.hasOwnProperty(e.name) ? user[e.name] : ""}
            name={e.name}
            // type={e.type}
            multiline
            minRows={4}
            fullWidth
            xs={{ minWidth: "min-content" }}
            helperText={
              user?.error && user.error.hasOwnProperty(e.value) ? (
                <MDTypography variant={"h6"} color={"error"}>
                  {user.error[e.value].message}
                </MDTypography>
              ) : (
                ""
              )
            }
          />
        </Box>
      );

    case "input":
      return (
        <Box sx={{ m: 1 }} justifySelf={"self-start"} xs={12} sm={9} lg={4} key={e.name}>
          {renderLabel()}
          <TextField
            key={e.name}
            // id={e.name}
            aria-describedby="my-helper-text"
            onChange={handleChange}
            required={e.required}
            value={user && user.hasOwnProperty(e.name) && e.type !== "file" ? user[e.name] : ""}
            name={e.name}
            type={e.type}
            fullWidth
            xs={{ minWidth: "min-content" }}
            helperText={
              user?.error && user.error.hasOwnProperty(e.value) ? (
                <MDTypography variant={"h6"} color={"error"}>
                  {user.error[e.value].message}
                </MDTypography>
              ) : (
                ""
              )
            }
          />
        </Box>
      );
    case "switch":
      return (
        <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <MDBox mt={0.5}>
            <Switch checked={true} />
          </MDBox>
          <MDBox width="80%" ml={0.5}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              Email me when someone follows me
            </MDTypography>
          </MDBox>
        </MDBox>
      );
    case "combobox":
      return (
        <Box sx={{ mb: 1 }} justifySelf={"self-start"} xs={12} sm={9} lg={4} key={e.name}>
          {renderLabel()}
          <FormControl fullWidth>
            <ComboBox handleChange={handleChange} user={user} inputProps={e} />
          </FormControl>
        </Box>
      );
    case "select":
      return (
        <MdSelect
          properties={e}
          label={renderLabel()}
          value={user && user.hasOwnProperty(e.name) ? user[e.name] : ""}
          handleChange={handleChange}
        />
      );
    case "radio":
      return (
        <MDRadio
          properties={e}
          label={renderLabel()}
          value={user && user.hasOwnProperty(e.name) ? user[e.name] : ""}
          handleChange={handleChange}
        />
      );
    default:
      return null;
  }
}
export default React.memo(InputComponent);
InputComponent.propTypes = {
  e: PropTypes.any,
  user: PropTypes.any,
  handleChange: PropTypes.any,
};
