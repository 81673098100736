import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { utils } from "../../assets/assetsFunctions";
import { emailsApi, reservationsAPI } from "../../assets/apiFunctions";
import LayoutHeader from "../../components/layoutHeader/layoutHeader";
import MDBox from "../../components/MDBox";
import Card from "@mui/material/Card";
import MDTypography from "../../components/MDTypography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import MDButton from "../../components/MDButton";
import useFormData from "../../hooks/useFormData";
import ResendConfirmationEmail from "../../layoutDependencies/LDreservations/resendConfirmationEmail";
import Box from "@mui/material/Box";

const ReservationDetails = () => {
  const [details, setDetails] = useState();
  useEffect(() => {
    const id = utils.getParamsFromUrl("id");
    reservationsAPI.getReservationById(id).then((res) => setDetails(res.data[0]));
  }, []);

  const a = [
    {
      title: "guest info",
      data: [
        {
          title: "guest name",
          value: "guest_name",
          nested: "with_details",
        },
        {
          title: "arrival",
          value: "start_date",
          nested: "",
        },
        {
          title: "departure",
          value: "end_date",
          nested: "",
        },
        {
          title: "Room",
          value: "room_name",
          nested: "",
        },
      ],
    },
    {
      title: "reservation",
      data: [
        {
          title: "booking reference number",
          value: "reservation_sn",
          nested: "",
        },
        {
          title: "booked at",
          value: "created_at",
          nested: "",
        },
        {
          title: "total guests",
          value: "guests_qty",
          nested: "with_details",
        },
        {
          title: "Telephone",
          value: "guest_telephone",
          nested: "with_details",
        },
        {
          title: "Per/night",
          value: "price_night",
          nested: "",
          prefix: "$",
        },
        {
          title: "email",
          value: "guest_email",
          nested: "with_details",
        },
      ],
    },
    {
      title: "guest message",
      data: [
        {
          title: "message",
          value: "guest_message",
          nested: "with_details",
        },
      ],
    },
    {
      title: "extra services",
      data: [
        {
          title: "booking reference number",
          value: "reservation_sn",
          nested: "",
        },
        {
          title: "total guests",
          value: "guests_qty",
          nested: "with_details",
        },
        {
          title: "Telephone",
          value: "guest_telephone",
          nested: "with_details",
        },
        {
          title: "email",
          value: "guest_email",
          nested: "with_details",
        },
      ],
    },
  ];
  if (!details) return null;
  return (
    <MDBox pt={6} pb={3}>
      <Card sx={{ marginTop: 2 }}>
        <LayoutHeader title={"Reservation Details"} />
        <MDBox
          sx={{ flexDirection: "column", display: "flex", justifyContent: "space-between" }}
          width={"100"}
          py={2}
          pt={3}
          px={3}
        >
          <MDBox display={"flex"}>
            <Box sm={3}>
              <ResendConfirmationEmail status={details} />
            </Box>
          </MDBox>
          <Grid container width={"100%"} sx={{ justifyContent: "space-between" }} rowGap={2}>
            {a?.map((e, index) => (
              <Grid
                item
                mt={0}
                display={"flex"}
                xs={12}
                md={6}
                gap={1}
                flexDirection={"column"}
                key={index}
              >
                <MDTypography
                  variant="h4"
                  fontWeight="bold"
                  textTransform={"capitalize"}
                  color={"info"}
                  mb={1}
                >
                  {e.title}
                </MDTypography>
                {e?.data?.map((x, index) => (
                  <MDBox
                    display={"flex"}
                    flexDirection={"row"}
                    gap={1}
                    key={index}
                    alignItems={"center"}
                  >
                    <MDTypography variant="h6" fontWeight="bold" textTransform={"capitalize"}>
                      {x.title}:
                    </MDTypography>
                    <MDTypography variant="h6" fontWeight="regular">
                      {details && details.hasOwnProperty(x.nested)
                        ? details[x.nested][x.value]
                        : details[x.value]}
                      {x?.prefix}
                    </MDTypography>
                  </MDBox>
                ))}
              </Grid>
            ))}
          </Grid>
        </MDBox>
      </Card>
    </MDBox>
  );
};

export default ReservationDetails;
