import React from "react";
import MDBox from "../../components/MDBox";
import Card from "@mui/material/Card";
import LayoutHeader from "../../components/layoutHeader/layoutHeader";
import Calendar from "./calendar";
import SidebarDetails from "./sidebarDetails";
import { RatesAvailabilityContextProvider } from "../../context/ratesAvailability";
/**
rates And Availability
 */
const Index = (props) => {
  return (
    <RatesAvailabilityContextProvider>
      <MDBox pt={6} pb={3}>
        <Card sx={{ marginTop: 2 }}>
          <LayoutHeader title={"Rooms Add"} />
          <MDBox pt={3}></MDBox>
          <MDBox display="flex" gap={1}>
            <Calendar />
            <SidebarDetails />
          </MDBox>
        </Card>
      </MDBox>
    </RatesAvailabilityContextProvider>
  );
};
export default Index;
