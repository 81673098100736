import moment from "moment/moment";

export const momentHelper = {
  removeSeconds: (time) => {
    return moment(time, "HH:mm").format("HH:mm");
  },
  minTime: (d) => {
    const m = d?.from;
    const [hours, minutes] = m.split(":");
    return moment().set({ hours, minutes });
  },
  minutes_to_hhmm: (numberOfMinutes, returnType) => {
    //create duration object from moment.duration
    let duration = moment.duration(numberOfMinutes, "minutes");
    //calculate hours
    let hh =
      duration.years() * (365 * 24) +
      duration.months() * (30 * 24) +
      duration.days() * 24 +
      duration.hours();
    //get minutes
    let mm = duration.minutes();
    if (returnType == "number") return hh + "." + mm;
    //return total time in hh:mm format
    return hh + "st " + mm + "min";
  },
  checkHour: (a, b) => {
    const momentTime1 = moment(a, "h:mm:ss");
    const momentTime2 = moment(b, "h:mm:ss");
    return momentTime1.isBefore(momentTime2) ? -1 : 1;
  },
  calculateHours: (workingTimes) => {
    let totalTime = 0;
    const today = moment("17-07-2023", "DD-MM-YYYY"); // Specify the format of the date string
    workingTimes.map((e) => {
      if (e?.from && e?.to) {
        let startTime = moment(`${today.format("YYYY-MM-DD")} ${e?.from}`); // Format the date part as "YYYY-MM-DD"
        let endTime = moment(`${today.format("YYYY-MM-DD")} ${e?.to}`);
        const duration = moment.duration(endTime.diff(startTime));
        const hours = Math.floor(duration.asHours()) * 60;
        const minutes = duration.minutes();
        totalTime += hours + minutes;
      }
    });
    return totalTime;
  },
  compareDays: (a, b) => {
    if (a.date.date_day < b.date.date_day) {
      return -1;
    } else if (a.date.date_day > b.date.date_day) {
      return 1;
    } else {
      return 0;
    }
  },
};
