/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React from "react";
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import { getAllMaterialVariables } from "../../context";
import TimeLineDay from "./timeLineDay";
import generateWeekDays from "../generateWeekDays/generateWeekDays";
import moment from "moment";
import { useEffect } from "react";
import { shiftsApi } from "../../assets/apiFunctions";

function WeeklyPlan() {
  const today = moment();
  const controller = getAllMaterialVariables();
  const [shifts, setShifts] = React.useState(null);
  const { startOfWeek, endOfWeek } = generateWeekDays(today);

  useEffect(() => {
    if (startOfWeek && endOfWeek) {
      const data = { startOfWeek: startOfWeek, endOfWeek: endOfWeek };
      shiftsApi.getUserShift(data).then((res) => setShifts(res.data.shifts));
    }
  }, []);
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={3} px={3}>
        <MDTypography variant="h6" fontWeight="medium">
          Weekly Plan
        </MDTypography>{" "}
        <MDBox
          mt={0}
          mb={2}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <MDBox>
            <MDTypography variant="button" color="text" fontWeight="medium">
              {controller.userName}
            </MDTypography>
          </MDBox>
          <MDBox mt={0} mb={2} display={"flex"} gap={1}>
            <MDTypography variant="button" color="text" fontWeight="medium">
              {startOfWeek}
              {/*{moment(startOfWeek).format("dd-M-y")}*/}
            </MDTypography>
            {"-"}
            <MDTypography variant="button" color="text" fontWeight="medium">
              {endOfWeek}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox p={2}>
        {shifts &&
          shifts.map((s) => (
            <TimeLineDay
              key={s.id}
              color="success"
              icon="calendar"
              title={s.date}
              dateTime="10:50 PM"
              // description={"home to krankenhouse"}
              from={s.shift_from}
              to={s.shift_to}
            />
          ))}
      </MDBox>
    </Card>
  );
}

export default WeeklyPlan;
