const commonProps = {
  onChange: "handleChange",
  required: false,
  type: "number",
  inputType: "input",
};

const roomDetails = [
  {
    ...commonProps,
    name: "type",
    label: "type name",

    required: true,
    type: "text",
  },
  {
    ...commonProps,
    name: "space",
    label: "space",
    required: true,
  },
  {
    ...commonProps,
    name: "capacity",
    label: "capacity",
    required: true,
  },
];

const patientAddressFields = [
  {
    ...commonProps,
    name: "room_description",
    label: "room description",
    value: "room_description",
    required: true,
    inputType: "textArea",
    type: "textArea",
  },
];
const roomImage = [
  {
    ...commonProps,
    name: "room_img",
    label: "room img",
    value: "room_img",
    required: false,
    type: "file",
  },
];

const formFields = [
  { name: "Room Details", fields: roomDetails },
  { name: "Room Description", fields: patientAddressFields },
  { name: "Room Image", fields: roomImage },
];

export default formFields;
