import axiosInstance from "../axiosInstance";
import { prefix } from "./prefixsVariables";

export const carsAPI = {
  createCar: async (data) => {
    return await axiosInstance.post(`${prefix.car}/create_car`, data);
  },
  getAllCars: async () => {
    return await axiosInstance.get(`${prefix.car}/get_car`);
  },
};
