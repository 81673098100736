import React from "react";
import { departmentAPI } from "../../../assets/apiFunctions";
import { setLoader, setNotification, useMaterialUIController } from "../../../context";
import messageNotification, { ErrorCodes } from "../../../assets/messageNotification";

const useDepartmentHook = () => {
  const [controller, dispatch] = useMaterialUIController();

  /**
   * Updates a department with the provided data.
   * @param {Object} updatedValue - The updated department data.
   * @returns {Promise<Object>} - The API response.
   */
  const createOrUpdateDepartment = async (updatedValue) => {
    try {
      setLoader(dispatch, true);
      const res = await departmentAPI.setDepartment(updatedValue);
      setLoader(dispatch, false);
      return res;
    } catch (error) {
      setLoader(dispatch, false);
      console.error("Error updating department:", error);
      setNotification(dispatch, ErrorCodes[error.response.data.code]);
      return null;
      // throw error; // Rethrow the error to propagate it up the call stack if needed.
    }
  };

  /**
   * Deletes a department with the specified ID.
   * @param {number} id - The ID of the department to delete.
   * @returns {Promise<Object>} - The API response.
   */
  const deleteDepartment = async (id) => {
    try {
      setLoader(dispatch, true);
      const res = await departmentAPI.delete(id);
      setLoader(dispatch, false);
      return res;
    } catch (error) {
      setLoader(dispatch, false);
      console.error("Error deleting department:", error);
      throw error; // Rethrow the error to propagate it up the call stack if needed.
    }
  };

  return { createOrUpdateDepartment, deleteDepartment };
};

export default useDepartmentHook;
