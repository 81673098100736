import React, { useEffect, useState } from "react";
import MDInput from "../../../components/MDInput";
import PropTypes from "prop-types";
import MDButton from "../../../components/MDButton";
import { Icon } from "@mui/material";
import { departmentAPI } from "../../../assets/apiFunctions";
import useDepartmentHook from "./useDepartmentHook";
import { useDepartmentContext } from "../../../context/departmentContext";

const DepartmentToUpdate = ({ value, changeInputState }) => {
  const [updatedValue, setValue] = useState(value);
  const { deleteDepartment, createOrUpdateDepartment } = useDepartmentHook();
  const { departments, setDepartments } = useDepartmentContext();
  const handleResponse = (res, status) => {
    if (res == null) return;
    if (status.type == "update") {
      const updatedDepartments = departments.map((department) =>
        department.id === res.data?.data?.id ? res.data.data : department
      );
      setDepartments(updatedDepartments);
    }
    if (status.type == "delete") {
      const updatedDepartments = departments.filter(
        (department) => department.id !== res.data?.data?.id
      );
      setDepartments(updatedDepartments);
    }
    changeInputState(false);
  };
  const submit = (type) => {
    switch (type) {
      case "update":
        // updates only when the 'name' is actually modified, optimizing efficiency.
        if (updatedValue.name !== value.name) {
          createOrUpdateDepartment(updatedValue).then((res) =>
            handleResponse(res, { type: "update" })
          );
        }
        break;
      case "delete":
        deleteDepartment(updatedValue.id).then((res) => handleResponse(res, { type: "delete" }));
        break;
    }
    changeInputState();
  };
  useEffect(() => {
    const i = document.getElementById(value.id);
    if (i) {
      i.focus();
    }
  }, []);
  return (
    <>
      <MDInput
        value={updatedValue?.name}
        id={value.id}
        onChange={(e) => setValue({ ...updatedValue, name: e.target.value })}
      />
      <MDButton variant={"text"} size={"medium"} onClick={() => submit("update")}>
        <Icon color={"success"}>check</Icon>
      </MDButton>
      <MDButton variant={"text"} size={"medium"} onClick={() => submit("delete")}>
        <Icon color={"error"}>delete</Icon>
      </MDButton>
    </>
  );
};

export default DepartmentToUpdate;
DepartmentToUpdate.propTypes = {
  value: PropTypes.object.isRequired,
  changeInputState: PropTypes.func.isRequired,
};
