import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { TimePicker } from "../../components/datePicker";
import moment from "moment/moment";
import MDInput from "../../components/MDInput";
import Label from "../../components/label/label";
import SubmitForm from "../../components/forms/submitForm";
import { shiftsApi } from "../../assets/apiFunctions";
import useFormData from "../../hooks/useFormData";
import { setLoader, setNotification, useMaterialUIController } from "../../context";
import messageNotification from "../../assets/messageNotification";

const ShiftsForm = (props) => {
  const initial = {
    name: "",
    from: "",
    to: "",
  };
  const [shiftTimes, setShiftTimes] = useState(initial);
  const [controller, dispatch] = useMaterialUIController();
  const handleChange = (name, value, isTime) => {
    let checkValue = isTime ? moment(value._d).format("HH:mm") : value;
    setShiftTimes({ ...shiftTimes, [name]: checkValue });
  };
  const submit = (e) => {
    e.preventDefault();
    if (shiftTimes.from && shiftTimes.to) {
      setLoader(dispatch, true);
      const formData = useFormData({ name: "shift", data: shiftTimes });
      shiftsApi
        .setShift(formData)
        .then((res) => setNotification(dispatch, messageNotification.SUCCESS_OPERATION))
        .catch((e) => console.log(e))
        .finally(() => setLoader(dispatch, false));
    }
  };
  return (
    <SubmitForm submit={submit}>
      <Grid container direction={"column"} spacing={2}>
        <Grid item xs={12} sm={"auto"}>
          <Label name={"shift name"} />
          <MDInput onChange={(e) => handleChange("name", e.target.value, false)} required={true} />
        </Grid>
        <Grid item xs={5} sm={"auto"}>
          <Label name={"Start shift"} />
          <TimePicker
            label={"from"}
            onChange={(value) => handleChange("from", value, true)}
            defaultValue={""}
            // minTime={checkFromMinTime(index)}
            // value={(d.from && moment(d.from, "HH:mm")) || null}
            required={true}
          />
        </Grid>
        <Grid item xs={5} sm={"auto"}>
          <Label name={"End shift"} />
          <TimePicker
            label={"To"}
            onChange={(value) => handleChange("to", value, true)}
            defaultValue={""}
            // minTime={utils.minTime(d)}
            // value={(d.to && moment(d.to, "HH:mm")) || null}
            required={true}
          />
        </Grid>
      </Grid>
    </SubmitForm>
  );
};

export default ShiftsForm;
