import React, { useState } from "react";
import SubmitForm from "../../../components/forms/submitForm";
import { FormHelperText, Grid } from "@mui/material";
import MDTypography from "../../../components/MDTypography";
import { DatePicker } from "../../../components/datePicker";
import MdSelect from "../../../components/MDRadio/MDRadio";
import Checkbox from "@mui/material/Checkbox";
import inputs from "../usersInputFildes";
import PropTypes from "prop-types";
import useFormData from "../../../hooks/useFormData";
import { setLoader, setNotification, useMaterialUIController } from "../../../context";
import { usersAPI } from "../../../assets/apiFunctions";
import messageNotification from "../../../assets/messageNotification";
import Label from "../../../components/label/label";

const EditUserForm = ({ user, userId }) => {
  const [withPassword, setWithPassword] = useState(false);
  const [userToUpdate, setUserToUpdate] = useState(user);
  const [controller, dispatch] = useMaterialUIController();
  const handleChange = (e) => {
    setUserToUpdate({ ...userToUpdate, [e.target.name]: e.target.value });
  };
  const handleChangeDate = (e) => {
    setUserToUpdate({ ...userToUpdate, startDate: e });
  };
  const checkError = () => {
    setUserToUpdate({ ...userToUpdate, error: {} });
    const setUserError = (key, message) => {
      setUserToUpdate({
        ...userToUpdate,
        error: { ...userToUpdate.error, [key]: true, [key + "message"]: message },
      });
    };
    Object.keys(userToUpdate).forEach((key) => {
      const value = userToUpdate[key];
      switch (key) {
        case "firstName":
          if (value.length < 3) {
            setUserError(key, "message");
          }
        case "password":
          if (userToUpdate?.password < 8) {
            setUserError(key, "Password must be at least 8 characters.");
          }
        case "password":
          if (userToUpdate?.password !== userToUpdate.confirmPassword) {
            setUserError(key, "Passwords don't match.");
          }
      }
    });
  };
  const submit = async (e) => {
    e.preventDefault();
    checkError();
    const formData = await useFormData({ name: "user", data: userToUpdate });
    setLoader(dispatch, true);
    // userToUpdate?.error &&
    //   Object.keys(userToUpdate?.error).length === 0 &&
    usersAPI
      .updateUser(formData, userId)
      .then((res) => {
        if (res.data.status == true) {
          setNotification(dispatch, messageNotification.SUCCESS_OPERATION);
          // setUser(userData);
        }
      })
      .catch((e) => setNotification(dispatch, messageNotification.FAILED_OPERATION))
      .finally(() => setLoader(dispatch, false));
  };
  const modifiedInputs = () => {
    if (!withPassword) {
      return inputs.filter((e) => e.name !== "Password");
    } else {
      return inputs;
    }
  };

  return (
    <SubmitForm
      user={userToUpdate}
      inputs={modifiedInputs()}
      submit={submit}
      handleChange={handleChange}
      // inputType={inputType}
    >
      <Grid container gap={2} m={1}>
        <Grid item justifySelf={"self-start"} xs={12} sm={6} lg={3}>
          <Label name={"Start date"} />
          <DatePicker
            name={"start_date"}
            value={userToUpdate?.start_date | null}
            onChange={
              handleChangeDate
              // setWorkingTimes({ ...workingTimes, day: e })
            }
          />
        </Grid>
        <Grid item justifySelf={"self-start"} xs={12} sm={9} lg={4}>
          <Label name={"type"} />
          <MdSelect
            properties={{ name: "role", options: ["admin", "user"] }}
            name={"role"}
            value={userToUpdate}
            defaultValue={userToUpdate.role[0]}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Label name={"Change Password"} />
          <Checkbox
            checked={withPassword}
            onChange={() => {
              if (userToUpdate) {
                const { password, ...data } = user;
                setUserToUpdate(data);
              }
              setWithPassword(!withPassword);
            }}
          />
        </Grid>
      </Grid>
    </SubmitForm>
  );
};

export default EditUserForm;

EditUserForm.propTypes = {
  user: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
};
