import React, { useEffect, useMemo, useState } from "react";
import UsersTable from "./usersTable";
import { usersAPI } from "../../assets/apiFunctions";
import { useNavigate } from "react-router-dom";
import { contentLoader } from "../../components/loader/loader";
import { getUserContextVariables, UsersContext } from "../../context/usersContext";
import AddUser from "./addUser";

const Index = (props) => {
  const navigate = useNavigate();
  const { users, setUsers } = getUserContextVariables();
  const [localLoader, setLocalLoader] = useState(false);
  //
  useEffect(() => {
    setLocalLoader(true);
    usersAPI
      .getUsers()
      .then((res) => {
        setUsers(res.data.users);
      })
      .catch((error) => {
        console.log(error); // Log the error
        return null; // Return a value or handle the error here
      })
      .finally(() => setLocalLoader(false));
  }, []);
  function onUserChange(status, data, id) {
    if (!users) return;
    if (status == "delete") {
      // Filter out the users with the specified id
      const updatedUsers = users.filter((user) => user.id !== id);
      // Update the users state with the filtered list
      setUsers(updatedUsers);
    } else if (status == "update") {
      const updatedUsers = users.map((user) => {
        if (user.id === id) {
          // If the user's ID matches the provided ID, update the status property
          const newStatus = data.status === true || data.status === 1 ? 1 : 0;
          return { ...user, disabled: newStatus };
        }
        return user; // Return the unmodified user for all other cases
      });
      setUsers(updatedUsers);
    }
  }
  const CheckRendering = () => {
    const hasUsers = users && users.length > 0;
    return !localLoader ? (
      hasUsers ? (
        <UsersTable onUserChange={onUserChange} users={users} navigate={navigate} />
      ) : (
        <div>No data</div>
      )
    ) : (
      contentLoader()
    );
  };
  return (
    <>
      <AddUser />
      <CheckRendering />
    </>
  );
};

export default Index;
