import React from "react";
import { FormHelperText } from "@mui/material";
import MDTypography from "../MDTypography";
import PropTypes from "prop-types";

const Label = ({ name }) => (
  <FormHelperText>
    <MDTypography textTransform={"uppercase"} variant="button" fontWeight="bold" color="text">
      {name}
    </MDTypography>
  </FormHelperText>
);

export default Label;
Label.propTypes = {
  name: PropTypes.string.isRequired,
};
