import React from "react";
import PropTypes from "prop-types";

export const WorkingHoursContext = React.createContext(null);

const WorkingHoursProvider = (props) => {
  const [userWhMonthly, setUserWhMonthly] = React.useState();
  return (
    <WorkingHoursContext.Provider value={{ userWhMonthly, setUserWhMonthly }}>
      {props.children}
    </WorkingHoursContext.Provider>
  );
};

WorkingHoursProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
export default WorkingHoursProvider;
