/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Timeline context
import { useTimeline } from "examples/Timeline/context";

// Custom styles for the TimelineItem
import timelineItem from "examples/Timeline/TimelineItem/styles";
import { utils } from "../../assets/assetsFunctions";
import moment from "moment";
function TimeLineDay({ color, icon, title, dateTime, description, from, to, lastItem, frei }) {
  const isDark = useTimeline();
  const { calculateHours, minutes_to_hhmm } = utils;
  return (
    <MDBox position="relative" mb={3} sx={(theme) => timelineItem(theme, { lastItem, isDark })}>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgcolor={color}
        color="white"
        width="2rem"
        height="2rem"
        borderRadius="50%"
        position="absolute"
        top="8%"
        left="2px"
        zIndex={2}
        sx={{ fontSize: ({ typography: { size } }) => size.sm }}
      >
        <Icon fontSize="inherit">{icon}</Icon>
      </MDBox>
      {frei && frei == true ? (
        <MDBox ml={5.75} pt={description ? 0.7 : 0.5} lineHeight={0} maxWidth="30rem">
          <MDTypography variant="button" fontWeight="medium" color={isDark ? "white" : "dark"}>
            {title}
          </MDTypography>
          <MDTypography>Frei</MDTypography>
        </MDBox>
      ) : (
        <MDBox ml={5.75} pt={description ? 0.7 : 0.5} lineHeight={0} maxWidth="30rem">
          <MDBox mt={0.5} alignItems={"center"} gap={1} display={"flex"}>
            <MDTypography variant="button" fontWeight="light" color={isDark ? "white" : "dark"}>
              {moment(title).format("dddd")}
            </MDTypography>
            <MDTypography variant="button" fontWeight="medium" color={isDark ? "white" : "dark"}>
              {title}
            </MDTypography>
          </MDBox>
          <MDBox mt={0.5} alignItems="center" gap={1} display={"flex"}>
            <MDTypography variant="button" fontWeight="medium" color={isDark ? "white" : "dark"}>
              Stunden
            </MDTypography>
            <MDTypography variant="caption" color={isDark ? "secondary" : "text"}>
              {minutes_to_hhmm(calculateHours([{ from: from, to: to }]))}
            </MDTypography>
          </MDBox>
          {/*<MDBox mt={2} mb={1.5}>*/}
          {/*  {description ? (*/}
          {/*    <MDTypography variant="button" color={isDark ? "white" : "dark"}>*/}
          {/*      {description}*/}
          {/*    </MDTypography>*/}
          {/*  ) : null}*/}
          {/*</MDBox>{" "}*/}
          <MDBox
            mt={2}
            mb={1.5}
            alignItems={"center"}
            justifyContent={"center"}
            display={"flex"}
            gap={2}
          >
            <MDBox
              display={"flex"}
              variant="button"
              gap={1}
              sx={{ textDecoration: "underline" }}
              fontWeight="medium"
              justifyContent={"center"}
            >
              <Icon fontSize={"small"}>schedule</Icon>
              <MDTypography variant="button" fontWeight="medium" color={isDark ? "white" : "dark"}>
                {from}
              </MDTypography>
            </MDBox>
            <MDBox
              display={"flex"}
              variant="button"
              gap={1}
              sx={{ textDecoration: "underline" }}
              fontWeight="medium"
              justifyContent={"center"}
            >
              <Icon fontSize={"small"}>schedule</Icon>
              <MDTypography variant="button" fontWeight="medium" color={isDark ? "white" : "dark"}>
                {to}
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      )}
    </MDBox>
  );
}

// Setting default values for the props of TimelineItem
TimeLineDay.defaultProps = {
  color: "info",
  lastItem: false,
  description: "",
  frei: false,
};

// Typechecking props for the TimelineItem
TimeLineDay.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  dateTime: PropTypes.string.isRequired,
  description: PropTypes.string,
  lastItem: PropTypes.bool,
  frei: PropTypes.bool,
  from: PropTypes.string,
  to: PropTypes.string,
};

export default TimeLineDay;
