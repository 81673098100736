/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
import React, { useEffect, useState } from "react";
import MDBox from "../../components/MDBox";
// import MDAvatar from "../../components/MDAvatar";
import MDTypography from "../../components/MDTypography";
// import MDBadge from "../../components/MDBadge";
import { Button, Icon } from "@mui/material";
import { roomsAPI, usersAPI } from "../../assets/apiFunctions";
import MDButton from "../../components/MDButton";
import CircularProgress from "@mui/material/CircularProgress";
import DataTable from "../../examples/Tables/DataTable";
import AlertDialog from "../../components/dialog/dialog";
import Status from "../roomTypes/status";
import { Link } from "react-router-dom";

const ReservationTables = ({ data }) => {
  const GuestName = ({ name, id }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox lineHeight={1}>
        <Link to={`/reservation_details?id=${id}`}>
          <MDTypography display="block" variant="caption" fontWeight="medium" color={"info"}>
            {name}
          </MDTypography>
        </Link>
      </MDBox>
    </MDBox>
  );
  const Text = ({ data }) => (
    <MDBox lineHeight={1} textAlign="left" display={"flex"} columnGap={5}>
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {data}
      </MDTypography>
    </MDBox>
  );
  const Status = ({ data }) => (
    <MDBox lineHeight={1} textAlign="left" display={"flex"} columnGap={5}>
      <MDTypography
        display="block"
        variant="caption"
        color={data?.color}
        fontWeight="medium"
        textTransform={"capitalize"}
      >
        {data?.name}
      </MDTypography>
    </MDBox>
  );

  const rows =
    data &&
    data.length > 0 &&
    data?.map((d) => ({
      // author: <Author image={room.image} name={room.room_name} email={room.email} />,
      name: <GuestName name={d.with_details.guest_name} id={d.reservation_sn} />,
      sn: <Text data={d.reservation_sn} />,
      type: <Text data={d.room_name} />,
      guests: <Text data={d.with_details.guests_qty} />,
      status: <Status data={d?.with_status?.with_type} />,
      arrival_time: <Text data={d.start_date} />,
      departure_time: <Text data={d.end_date} />,
    }));

  const columns = [
    { Header: "Name", accessor: "name", align: "left" },
    { Header: "room Type", accessor: "type", align: "left" },
    { Header: "guests", accessor: "guests", align: "center" },
    { Header: "sn", accessor: "sn", align: "center" },
    { Header: "status", accessor: "status", align: "center" },
    { Header: "arrival_time", accessor: "arrival_time", align: "center" },
    { Header: "departure_time", accessor: "departure_time", align: "center" },
  ];
  if (!rows || !columns) return null;
  return (
    <DataTable
      table={{ rows, columns }}
      isSorted={true}
      canSearch={false}
      entriesPerPage={false}
      showTotalEntries={false}
      noEndBorder
    />
  );
};

export default React.memo(ReservationTables);
