import React from "react";
import MDBox from "../../components/MDBox";
import Card from "@mui/material/Card";
import LayoutHeader from "../../components/layoutHeader/layoutHeader";
import AddDepartments from "./addDepartments";
import View from "./view";
import { DepartmentContextProvider } from "../../context/departmentContext";

//Departments Layout
const Index = (props) => {
  return (
    <MDBox pt={6} pb={3}>
      <DepartmentContextProvider>
        <Card sx={{ marginTop: 2 }}>
          <LayoutHeader title={"Set Department"} />
          <MDBox pt={3}></MDBox>
          <AddDepartments />
        </Card>
        <Card sx={{ marginTop: 2 }}>
          <View />
        </Card>
      </DepartmentContextProvider>
    </MDBox>
  );
};

export default Index;
