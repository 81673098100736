/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Patient from "./patient";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";
function PatientsMobileView({ data }) {
  const [searchQuery, setSearchQuery] = React.useState("");
  const filteredData = data.filter((patient) => {
    const fullName = `${patient.first_name} ${patient.last_name}`;
    return fullName.toLowerCase().includes(searchQuery.toLowerCase());
  });

  return (
    <Card>
      <MDBox
        pt={2}
        px={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        bgcolor={"info"}
      >
        <MDTypography variant="h6" fontWeight="medium" color={"white"}>
          Patients List
        </MDTypography>
        <MDBox pt={2} pb={2}>
          <TextField
            // id={e.name}
            aria-describedby="my-helper-text"
            onChange={(e) => setSearchQuery(e.target.value)}
            type="text"
            placeholder="Search patients"
            value={searchQuery}
            fullWidth
            xs={{ minWidth: "min-content" }}
          />
        </MDBox>
      </MDBox>
      <MDBox p={2}>
        <MDBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
          sx={{ maxHeight: "500px" }}
          className={"hide-scroll"}
        >
          {filteredData.map((patient) => (
            <Patient
              key={patient.id}
              firstName={patient.first_name}
              id={patient.id}
              lastName={patient.last_name}
              phone={patient.phone}
              gender={patient.gender}
              address={patient.with_address}
            />
          ))}
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default React.memo(PatientsMobileView);
PatientsMobileView.propTypes = {
  data: PropTypes.array.isRequired,
};
