import React from "react";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import AlertDialog from "../../../components/dialog/dialog";
import { Icon } from "@mui/material";
import PropTypes from "prop-types";
import { workingHoursAPI } from "../../../assets/apiFunctions";
import MDButton from "../../../components/MDButton";
import { utils } from "../../../assets/assetsFunctions";

const RenderHourComp = ({ hour, isAdmin }) => {
  const id = hour.id || hour.working_hour_id;
  const [disable, setDisable] = React.useState(false);
  const [updated, setUpdated] = React.useState(false);
  const actions = () => {
    if (hour.status !== "accepted") {
      return (
        <MDBox display={"flex"} disabled={disable}>
          <MDButton size={"large"} onClick={() => handleUserWorkingHours("accepted")}>
            <Icon color={"success"}>check</Icon>
          </MDButton>{" "}
          <MDButton size={"large"} disabled={disable}>
            <Icon color={"error"}>delete</Icon>
          </MDButton>
        </MDBox>
      );
    } else {
      return (
        <MDBox display={"flex"} disabled={disable}>
          <MDButton size={"large"} onClick={() => handleUserWorkingHours("pending")}>
            <Icon color={"success"}>restore</Icon>
          </MDButton>{" "}
          {/*<MDButton size={"large"} disabled={disable}>*/}
          {/*  <Icon color={"error"}>delete</Icon>*/}
          {/*</MDButton>*/}
        </MDBox>
      );
    }
  };
  function handleUserWorkingHours(action) {
    //action is ["deleted","accepted"]
    setDisable(true);
    workingHoursAPI.acceptUserWorkingHours(id, { action: action }).then((res) => setUpdated(true));
  }
  return (
    <MDBox
      display="flex"
      variant="contained"
      flexDirection="row"
      style={{ alignItems: "center" }}
      onDoubleClick={() => alert(hour.id)}
      gap={1}
    >
      <MDBox display="flex" variant="contained" flexDirection="column" className={"points"} gap={3}>
        <MDTypography fontSize={"16px"} variant="caption" color="text" fontWeight={"bold"}>
          {hour.from}
        </MDTypography>{" "}
        <MDTypography fontSize={"16px"} variant="caption" color="text" fontWeight={"bold"}>
          {hour.to}
        </MDTypography>
      </MDBox>
      <MDBox>
        <MDTypography
          fontSize={"16px"}
          variant="caption"
          color="text"
          fontWeight={"regular"}
          sx={{ fontStyle: disable && "italic" }}
        >
          {hour?.status}
        </MDTypography>{" "}
        {updated ? (
          <MDTypography fontSize={"16px"} variant="caption" color="success" fontWeight={"bold"}>
            updated
          </MDTypography>
        ) : null}
      </MDBox>
      {utils.callbackFunction(actions(), isAdmin)}
      {/*<MDTypography variant="caption" color="text" fontWeight={"bold"}>*/}
      {/*  Modified*/}
      {/*</MDTypography>*/}
    </MDBox>
  );
};

export default RenderHourComp;
RenderHourComp.propTypes = {
  hour: PropTypes.any.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};
/**
 *       <AlertDialog
 *         disable={disable ? true : false}
 *         onAgree={(e) => {
 *           handleUserWorkingHours(hour.working_hour_id, "accepted");
 *           // handleDelete(date.id);
 *         }}
 *         id={hour.working_hour_id}
 *         triggerText={"yes"}
 *         title={"name"}
 *         disagreeText={"ss"}
 *         agreeText={"delete"}
 *         content={"here is the content"}
 *         icon={isAdmin && <Icon color={"success"}>check</Icon>}
 *       />{" "}
 *       <AlertDialog
 *         disable={disable ? true : false}
 *         onAgree={(e) => {
 *           handleUserWorkingHours(hour.working_hour_id, "deleted");
 *           // handleDelete(date.id);
 *         }}
 *         id={hour.working_hour_id}
 *         triggerText={"yes"}
 *         title={"name"}
 *         disagreeText={"ss"}
 *         agreeText={"delete"}
 *         content={"here is the content"}
 *         icon={isAdmin && <Icon color={"error"}>delete</Icon>}
 *       />
 */
