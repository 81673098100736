/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
import React, { useEffect, useState } from "react";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import { roomsAPI, usersAPI } from "../../assets/apiFunctions";
import AlertDialog from "../../components/dialog/dialog";
import DataTable from "../../examples/Tables/DataTable";
import { useNavigate } from "react-router-dom";
import { SERVER_IMAGES } from "../../assets/axiosInstance";

const RoomsTable = ({ onUserChange }) => {
  const navigate = useNavigate();
  const [rooms, setRooms] = useState(null);
  useEffect(() => {
    roomsAPI.getRooms().then((res) => setRooms(res.data.rooms));
  }, []);

  function handleDelete(id) {
    usersAPI
      .deleteUser(id)
      .then((res) => {
        onUserChange("delete", {}, id);
      })
      .catch((e) => console.log(e));
  }
  const Author = ({ name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );
  const Type = ({ title }) => (
    <MDBox lineHeight={1} textAlign="left" display={"flex"} columnGap={5}>
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
    </MDBox>
  );
  const Qty = ({ q }) => (
    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      {q}
    </MDTypography>
  );
  const RoomImage = ({ src }) => (
    <MDBox
      sx={{ width: "100px", height: "100px", overflow: "hidden" }}
      variant="contained"
      color="text"
      fontWeight="medium"
    >
      <img
        src={`${SERVER_IMAGES}/${src}`}
        style={{ objectFit: "cover", maxWidth: "100%", height: "100%" }}
      />
    </MDBox>
  );
  const Action = ({ action, id }) => (
    <MDBox display={"flex"} gap={1} alignItems={"center"}>
      <AlertDialog
        onAgree={(e) => handleDelete(id)}
        id={id}
        triggerText={"Delete"}
        title={"name"}
        disagreeText={"Cancel"}
        content={`Are you sure you want to delete " ${name}"?`}
        agreeText={"delete"}
      />
      <MDTypography
        variant="caption"
        color="text"
        fontWeight="medium"
        onClick={() => navigate(`/rooms/edit?id=${id}`)}
      >
        {action}
      </MDTypography>
    </MDBox>
  );

  const rows =
    rooms &&
    rooms.length > 0 &&
    rooms?.map((room) => ({
      author: <Author image={room.image} name={room.room_name} email={room.email} />,
      type: <Type title={room.type} />,
      qty: <Qty q={room.qty} />,
      image: <RoomImage src={room.room_img} />,
      action: <Action action="Edit" id={room.id} />,
    }));

  const columns = [
    { Header: "Name", accessor: "author", width: "20%", align: "left" },
    { Header: "room Type", accessor: "type", align: "left" },
    { Header: "rooms available", accessor: "qty", align: "center" },
    { Header: "Type Image", accessor: "image", align: "center" },
    { Header: "Action", accessor: "action", align: "center" },
  ];
  if (!rows || !columns) return null;
  return (
    <DataTable
      table={{ rows, columns }}
      isSorted={false}
      canSearch={true}
      entriesPerPage={true}
      showTotalEntries={false}
      noEndBorder
    />
  );
};

export default React.memo(RoomsTable);
