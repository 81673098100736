/* eslint-disable react/prop-types */
import React, { useState } from "react";
import Sidenav from "../../examples/Sidenav";
import brandDark from "../../assets/images/logo-ct-dark.png";
import brandWhite from "../../assets/images/logo-ct.png";
import routes from "../../routes";
import { setMiniSidenav, useMaterialUIController } from "../../context";
import MDBox from "../MDBox";

const WithSideBarLayout = (props) => {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    userName,
    notification,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  return (
    <>
      <Sidenav
        color={sidenavColor}
        brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
        brandName={process.env.REACT_APP_APP_NAME}
        routes={routes}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      />
      {props.children}
    </>
  );
};

export default WithSideBarLayout;
