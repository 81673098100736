import axiosInstance from "../axiosInstance";
import { prefix } from "./prefixsVariables";

export const workingHoursAPI = {
  getHoursBetweenDates: async (data) => {
    return await axiosInstance.post(`${prefix.workingHours}/get_totalHours_between_date`, data);
  },
  acceptUserWorkingHours: async (id, data) => {
    return await axiosInstance.post(`${prefix.workingHours}/handle_user_working_hours/${id}`, data);
  },
  sendWorkingHours: async (data) => {
    return await axiosInstance.post(`${prefix.workingHours}/add_date`, data);
  },
  getWorkingHours: async (data) => {
    return await axiosInstance.get(`${prefix.workingHours}/getWorkingHours`, data);
  },
  deleteSpecificDay: async (id) => {
    return await axiosInstance.post(`${prefix.workingHours}/delete_day/${id}`);
  },
  getUsersWorkingHours: async (data) => {
    return await axiosInstance.post(`${prefix.workingHours}/get_users_working_hours`, data);
  },
};
