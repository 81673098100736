/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
import React, { useEffect } from "react";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
import { Page } from "react-pdf";
import moment from "moment";

function PrintableData({ rows, columns, printRef, date }) {
  console.log(date);
  return (
    <div style={{ width: "100%" }} ref={printRef} className={"printable-container-component"}>
      <MDBox px={2} py={3}>
        <MDBox mx={2} mt={-3} py={3} px={2}>
          <MDBox mt={1}>
            <MDTypography variant="h2" color="error" style={{ textAlign: "center" }}>
              Kara Markt
            </MDTypography>{" "}
          </MDBox>
          <MDTypography variant="h6" color="dark">
            Rawad Obied
          </MDTypography>{" "}
          <MDTypography variant="h6" color="dark">
            From:{moment(date?.startDate).format("YYYY-MM-DD")}
          </MDTypography>{" "}
          <MDTypography variant="h6" color="dark">
            To:{moment(date?.endDate).format("YYYY-MM-DD")}
          </MDTypography>{" "}
          <MDTypography variant="h6" color="dark">
            Date:{moment().format("YYYY-MM-DD HH:mm:ss")}
          </MDTypography>
        </MDBox>
        <table>
          <thead>
            <tr>
              {columns.map((column, index) => (
                <th key={index}>{column.Header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map((column, columnIndex) => (
                  <td key={columnIndex}>{renderCell(row, column)}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </MDBox>
    </div>
  );

  // Render a cell based on the row and column data
  function renderCell(row, column) {
    if (column.accessor === "userRow") {
      return <div>{row.userRow}</div>;
    } else if (column.accessor === "day") {
      return <div>{row.day}</div>;
    } else if (column.accessor === "hours") {
      return <div>{row.hours}</div>;
    } else if (column.accessor === "totalHours") {
      return <div>{row.totalHours}</div>;
    } else if (column.accessor === "action") {
      return <div>{row.action}</div>;
    } else {
      return "";
    }
  }
}

export default PrintableData;
