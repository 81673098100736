/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-routers components
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { utils } from "../../assets/assetsFunctions";
import React from "react";
import { workingHoursAPI } from "../../assets/apiFunctions";
import AlertDialog from "../../components/dialog/dialog";
import RenderHours from "./components/renderHours";
import { Icon } from "@mui/material";

function WorkingHoursList({ title, data, shadow }) {
  function handleDelete(id) {
    workingHoursAPI.deleteSpecificDay(id).then((r) => console.log(id));
  }
  const renderProfiles = data.working_days.map(({ date, hours, description, action }) => (
    // style={{ backgroundColor: "rgba(255, 140, 0,0.42)" }}
    <Accordion key={date.id}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id={date.id}
      >
        <MDBox
          style={{
            width: "100%",
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <MDTypography variant="button" fontWeight="medium">
            {date?.date_day}
          </MDTypography>
          <MDTypography variant="button" fontWeight="medium">
            {utils.minutes_to_hhmm(utils.calculateHours(hours))}
          </MDTypography>
          <MDBox fontWeight="medium" color={"success"}>
            {hours[0].status === "pending" ? (
              <MDTypography variant="button" fontWeight="bold" color={"warning"}>
                Pending
              </MDTypography>
            ) : (
              <MDTypography variant="button" fontWeight="bold" color={"success"}>
                Accepted
              </MDTypography>
            )}
          </MDBox>
          <AlertDialog
            onAgree={(e) => {
              e.stopPropagation(); // Prevent event propagation
              handleDelete(date.id);
            }}
            id={date.id}
            triggerText={date.date_day}
            title={"name"}
            disagreeText={date.date_day}
            agreeText={"delete"}
            content={"here is the content"}
            icon={<Icon color={"error"}>delete</Icon>}
          />
        </MDBox>
      </AccordionSummary>
      <AccordionDetails>
        <MDBox component="li" display="flex" alignItems="center" py={1} mb={1}>
          <MDBox mr={2}>{/*<MDAvatar src={image} alt="something here" shadow="md" />*/}</MDBox>
          <MDBox
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
          >
            <RenderHours hours={hours} />
          </MDBox>
          <MDBox ml="auto">
            <MDButton
              component="a"
              // href={action.route}
              target="_blank"
              rel="noreferrer"
              variant="text"
              color={"success"}
            ></MDButton>
          </MDBox>
        </MDBox>
      </AccordionDetails>
    </Accordion>
  ));
  return (
    <>
      <MDBox pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium">
          {title}
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <MDBox component="div" display="flex" flexDirection="column" p={0} m={0}>
          {renderProfiles}
        </MDBox>
        <MDBox
          display="flex"
          flexDirection="row"
          pt={2}
          style={{
            width: "100%",
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            Total hours
          </MDTypography>
          <MDTypography
            variant="h6"
            fontWeight="medium"
            textTransform="capitalize"
            color={"success"}
          >
            {utils.minutes_to_hhmm(data?.total_hours, "string")}
          </MDTypography>
        </MDBox>
      </MDBox>
    </>
  );
}

// Setting default props for the ProfilesList
WorkingHoursList.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfilesList
WorkingHoursList.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  shadow: PropTypes.bool,
};

export default WorkingHoursList;
