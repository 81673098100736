import axiosInstance from "../axiosInstance";
import { prefix } from "./prefixsVariables";

export const shiftsApi = {
  setUserShift: async (data) => {
    return await axiosInstance.post(`${prefix.shift}/set_user_shift`, data);
  },
  setShift: async (data) => {
    return await axiosInstance.post(`${prefix.shift}/set_shift`, data);
  },
  getShifts: async (data) => {
    return await axiosInstance.get(`${prefix.shift}/get_shifts`);
  },
  getUsersShift: async (data) => {
    return await axiosInstance.post(`${prefix.shift}/get_users_shifts`, data);
  },
  getUserShift: async (data) => {
    return await axiosInstance.post(`${prefix.shift}/get_user_shifts`, data);
  },
};
