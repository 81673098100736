import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useEffect } from "react";
import { otherAPI } from "../../assets/apiFunctions";
import { Stack } from "@mui/material";
import axios from "axios";
import PropTypes from "prop-types";
export default function ComboBox({ handleChange, inputProps, user }) {
  const [str, setStr] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const [c, setC] = React.useState(1);
  useEffect(() => {
    (str !== "") & (str?.length > 2) &&
      otherAPI.filterPlace("name", str).then((res) => {
        const data = res.data;
        const uniqueData = data.filter(
          (obj, index, self) => index === self.findIndex((o) => o.label === obj.label)
        );
        setOptions(uniqueData);
      });
  }, [str]);
  async function cry(data) {
    return data;
  }
  async function fetchData(n) {
    axios
      .get(
        `https://openplzapi.org/de/Streets?postalCode=33102&locality=paderborn&page=${n}&pageSize=10`
      )
      .then((res) => {
        if (res) {
          cry(res.data).then((data) => {
            const formData = new FormData();
            formData.append("data", JSON.stringify(data));
            otherAPI.setPlacesToDatabase(formData).then((res) => {
              if (c < 30) {
                setC(c + 1);
              }
            });
          });
        }
      });
  }
  function generateEvent(name, value) {
    return handleChange({ target: { name: name, value: value } });
  }
  return (
    <Stack>
      <Autocomplete
        freeSolo
        value={user && user.hasOwnProperty(inputProps.name) ? user[inputProps.name] : ""}
        onChange={(e, selectedValue) => generateEvent(inputProps.name, selectedValue)}
        options={options.map((option) => option.label)}
        renderInput={(params) => (
          <TextField
            name={inputProps.name}
            {...params}
            onChange={(e) => {
              setStr(e.target.value);
              handleChange(e);
            }}
          />
        )}
      />
    </Stack>
  );
}
ComboBox.propTypes = {
  handleChange: PropTypes.func,
  user: PropTypes.any,
  inputProps: PropTypes.any.isRequired,
};
