import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";
import { departmentAPI, otherAPI, roomsAPI } from "../../assets/apiFunctions";
import MDTypography from "@mui/material/Typography";

const MdSelect = ({ label, properties, handleChange, value }) => {
  const [options, setOptions] = React.useState(null);
  const [selectedContractType, setSelectedContractType] = React.useState("");

  const getOptions = {
    contract: () => otherAPI.contractTypes().then((res) => setOptions(res.data.data)),
    departments: () => departmentAPI.getDepartmentAPI().then((res) => setOptions(res.data.data)),
    roomTypes: () => roomsAPI.getRoomTypes().then((res) => setOptions(res.data.data)),
  };

  useEffect(() => {
    let d = properties.options;
    if (d && getOptions[d]) {
      getOptions[d](); // Call the function associated with the provided option
    }
  }, []);
  if (!options) return null;
  return (
    <Box sx={{ m: 1 }} justifySelf={"self-start"} xs={12} sm={9} lg={4} key={properties.name}>
      {label}
      <Select
        name={properties.name}
        value={value}
        sx={{
          width: "100%",
          // display: "flex",
          "& .css-1cohrqd-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select":
            {
              padding: "0.8rem !important", // Reset padding to default value
            },
        }}
        label="Age"
        onChange={(e) => {
          handleChange(e);
          setSelectedContractType(e.target.value);
        }}
      >
        {options &&
          options.map((o) => (
            <MenuItem key={o.id} value={o.id}>
              <MDTypography variant={"h7"}> {o.name || o?.type}</MDTypography>
            </MenuItem>
          ))}
      </Select>
    </Box>
  );
};

export default MdSelect;
MdSelect.propTypes = {
  label: PropTypes.any,
  properties: PropTypes.any,
  handleChange: PropTypes.func,
  value: PropTypes.string,
};
