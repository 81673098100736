import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import breakpoints from "../../assets/theme/base/breakpoints";
import PropTypes from "prop-types";

const TabComponent = ({ onChange, tabsData }) => {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  function handleTabsOrientation() {
    return window.innerWidth < breakpoints.values.sm
      ? setTabsOrientation("vertical")
      : setTabsOrientation("horizontal");
  }
  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
    onChange(tabsData[newValue].label);
  };
  return (
    <Grid item sx={{ ml: "auto" }}>
      <AppBar position="static">
        <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
          {tabsData?.map((tab, index) => (
            <Tab
              key={index}
              label={tab.label}
              icon={
                <Icon fontSize="small" color={tab.color} sx={{ mt: -0.25 }}>
                  {tab.icon}
                </Icon>
              }
            />
          ))}
        </Tabs>
      </AppBar>
    </Grid>
  );
};

TabComponent.propTypes = {
  onChange: PropTypes.func,
  tabsData: PropTypes.array,
};
export default TabComponent;
