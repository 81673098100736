import React, { useState } from "react";
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import CircularProgress from "@mui/material/CircularProgress";
import { roomsAPI } from "../../assets/apiFunctions";

//eslint-disable-next-line
const Status = ({status, id}) => {
  const [loadingButton, setLoadingButton] = useState(false);
  const [defaultStatus, setDefault] = useState(status);

  function handleChangeRoomStatus(id) {
    setLoadingButton(true);
    roomsAPI
      .changeRoomStatus({ status: defaultStatus }, id)
      .then((res) => {
        let intValue = defaultStatus ? 1 : 0;
        setDefault(!intValue);
        // onUserChange("update", data, id);
      })
      .finally(() => setLoadingButton(null));
  }

  return (
    <MDBox ml={-1}>
      <MDButton
        color={defaultStatus == 0 ? "success" : "light"}
        size={"small"}
        onClick={() => handleChangeRoomStatus(id)}
        disabled={loadingButton}
      >
        {loadingButton ? (
          <CircularProgress color={"secondary"} size={"20px"} />
        ) : defaultStatus === 0 ? (
          "Enabled"
        ) : (
          "Disabled"
        )}
      </MDButton>
    </MDBox>
  );
};

export default Status;
