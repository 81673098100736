import React from "react";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import Card from "@mui/material/Card";
import ShiftsForm from "./shiftsForm";
import { Paper } from "@mui/material";
import LayoutHeader from "../../components/layoutHeader/layoutHeader";
import ShiftsContextProvider from "../../context/shiftsContext";
import ShiftsView from "./shfitsView";
import SickReport from "../../components/sickReporting/sickReport";

const Shift = (props) => {
  return (
    <MDBox pt={6} pb={3}>
      <SickReport />
      <ShiftsContextProvider>
        <Card sx={{ marginTop: 2 }}>
          <LayoutHeader title={"Set your Shifts"} />
          <MDBox pt={3}></MDBox>
          <Paper sx={{ padding: "20px" }}>
            <ShiftsForm />
          </Paper>
        </Card>
        <Card sx={{ marginTop: 2 }}>
          <ShiftsView />
        </Card>
      </ShiftsContextProvider>
    </MDBox>
  );
};

export default Shift;
