const commonProps = {
  onChange: "handleChange",
};

const userDetailsFields = [
  {
    ...commonProps,
    name: "name",
    label: "First Name",
    value: "name",
    required: true,
    type: "text",
    inputType: "input",
  },
  {
    name: "fk_department",
    label: "department",
    value: "fk_department",
    inputType: "select",
    ...commonProps,
    type: "select",
    options: "departments",
  },
  {
    ...commonProps,
    name: "email",
    label: "Email",
    value: "email",
    required: true,
    type: "email",
    inputType: "input",
  },
  {
    name: "phone",
    label: "Phone",
    value: "phone",
    inputType: "input",
    type: "number",
    ...commonProps,
  },
];

const jobDetailsFields = [
  {
    name: "holidays",
    label: "Holy days",
    value: "holidays",
    inputType: "input",
    ...commonProps,
  },
  {
    name: "salary_hour",
    label: "Salary PH",
    value: "salary_hour",
    inputType: "input",
    ...commonProps,
  },
  {
    name: "contract_type",
    label: "Contract type",
    value: "contract_type",
    inputType: "select",
    ...commonProps,
    type: "select",
    options: "contract",
  },
];

const passwordFields = [
  {
    ...commonProps,
    name: "password",
    label: "Password",
    value: "password",
    inputType: "input",
    required: true,
    type: "password",
  },
  {
    ...commonProps,
    name: "confirmPassword",
    label: "Confirm Password",
    inputType: "input",
    value: "confirmPassword",
    required: true,
    type: "password",
  },
];

const formFields = [
  { name: "User Details", fields: userDetailsFields },
  { name: "Job Details", fields: jobDetailsFields },
  { name: "Password", fields: passwordFields },
];

export default formFields;

export const initialUser = {
  name: "",
  LastName: "",
  email: "",
  password: "",
  phone: "",
  confirmPassword: "",
  error: {},
  startDate: "",
  contract_type: "",
  salaryPH: "",
  holy_days: "",
  role: "",
};
