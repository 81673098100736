import React, { useContext, useState } from "react";
import { useMaterialUIController } from "../../context";
import PatientsTable from "./patientsTable";
import { contentLoader } from "../../components/loader/loader";
import AddPatient from "./addPatient";
import PatientsMobileView from "./components/patientsMobileView";
import { PatientContext } from "../../context/patientContext";

const Patients = (props) => {
  const { patients, localLoader } = useContext(PatientContext);
  const [controller, dispatch] = useMaterialUIController();
  const { roles } = controller;

  return (
    <>
      {roles && roles?.r?.find((role) => [3000].includes(role)) ? (
        <>
          <AddPatient />
          {!localLoader ? (
            patients ? (
              <PatientsTable users={patients} dispatch={dispatch} />
            ) : (
              <div>No data</div>
            )
          ) : (
            contentLoader()
          )}
        </>
      ) : patients ? (
        <PatientsMobileView data={patients} />
      ) : (
        contentLoader()
      )}
    </>
  );
};

export default Patients;
