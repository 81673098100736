const changePasswordFields = [
  {
    name: "current_password",
    label: "Old Password",
    value: "current_password",
    required: true,
    type: "text",
    inputType: "input",
  },
  {
    name: "password",
    label: "New Password",
    value: "password",
    required: true,
    type: "password",
    inputType: "input",
  },
  {
    name: "confirmPassword",
    label: "Confirm Password",
    value: "confirmPassword",
    required: true,
    type: "password",
    inputType: "input",
  },
];
const formFields = [{ name: "Fill this fields", fields: changePasswordFields }];

export default formFields;
