import React, { useState } from "react";
import { Card, Grid, Box, FormHelperText, Divider } from "@mui/material";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import SubmitForm from "../../components/forms/submitForm";
import formFields, { initialUser } from "./usersInputFildes";
import { DatePicker } from "../../components/datePicker";
import { setLoader, setNotification, useMaterialUIController } from "../../context";
import { usersAPI } from "../../assets/apiFunctions";
import messageNotification from "../../assets/messageNotification";
import { getUserContextVariables } from "../../context/usersContext";
import MDRadio from "../../components/MDRadio/MDRadio";
import UseFormData from "../../hooks/useFormData";
import LayoutHeader from "../../components/layoutHeader/layoutHeader";

const AddUser = (props) => {
  const [user, setUser] = useState(initialUser);
  const { users, updateUsers } = getUserContextVariables();
  const [, dispatch] = useMaterialUIController();
  const options = ["admin", "user"];

  const onReset = () => {
    setUser(initialUser);
  };

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleChangeDate = (e) => {
    setUser({ ...user, startDate: e });
  };

  const checkError = () => {
    setUser({ ...user, error: {} });
    const setUserError = (key, message) => {
      setUser({ ...user, error: { ...user.error, [key]: true, [key + "message"]: message } });
    };

    Object.keys(user).forEach((key) => {
      const value = user[key];
      switch (key) {
        case "firstName":
          if (value.length < 3) {
            setUserError(key, "message");
          }
          break;
        case "password":
          if (user.password < 8) {
            setUserError(key, "Password must be at least 8 characters.");
          }
          if (user.password !== user.confirmPassword) {
            setUserError(key, "Passwords don't match.");
          }
          break;
        default:
          break;
      }
    });
  };

  const submit = async (e) => {
    e.preventDefault();
    checkError();
    if (Object.keys(user.error).length === 0) {
      const formData = await UseFormData({ name: "user", data: user });
      setLoader(dispatch, true);
      usersAPI
        .createUser(formData)
        .then((res) => {
          if (res.data.status === true) {
            setNotification(dispatch, messageNotification.SUCCESS_OPERATION);
            updateUsers(res?.data?.user_details);
            setUser(initialUser);
          }
        })
        .catch(() => setNotification(dispatch, messageNotification.Exist_user))
        .finally(() => setLoader(dispatch, false));
    }
  };

  return (
    <MDBox pt={6} pb={3}>
      <Card sx={{ marginTop: 2 }}>
        <LayoutHeader title={"Add User"} />
        <MDBox pt={3}></MDBox>
        <SubmitForm
          user={user}
          submit={submit}
          handleChange={handleChange}
          inputs={formFields}
          reset={onReset}
        >
          <Divider sx={{ mx: 1, width: "100%" }} color={"red"} light={false} textAlign={"center"} />
          <Grid item xs={12} sm={4}>
            <Box sx={{ m: 2 }} justifySelf={"self-start"} xs={12} sm={12} lg={12}>
              <FormHelperText id="outlined-weight-helper-text">
                <MDTypography
                  textTransform={"capitalize"}
                  variant="button"
                  fontWeight="bold"
                  color="text"
                >
                  Start Date
                </MDTypography>
              </FormHelperText>
              <DatePicker
                name={"startDate"}
                value={user?.startDate | null}
                onChange={handleChangeDate}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <MDRadio
              properties={{ name: "role", options: options }}
              options={options}
              value={user}
              name={"role"}
              onChange={handleChange}
              sx={{ m: 2, width: "25ch" }}
            />
          </Grid>
        </SubmitForm>
      </Card>
    </MDBox>
  );
};

export default React.memo(AddUser);
