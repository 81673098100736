/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
import React, { useContext, useEffect, useState } from "react";
import MDBox from "../../components/MDBox";
import MDAvatar from "../../components/MDAvatar";
import MDTypography from "../../components/MDTypography";
import MDBadge from "../../components/MDBadge";
import { Button, Icon } from "@mui/material";
import { utils } from "../../assets/assetsFunctions";
import AlertDialog from "../../components/dialog/dialog";
import { setLoader } from "../../context";
import { patientsAPI } from "../../assets/apiFunctions";
import { PatientContext } from "../../context/patientContext";
import DataTable from "../../examples/Tables/DataTable";
import { useNavigate } from "react-router-dom";

const PatientsTable = ({ users, dispatch }) => {
  const { updatePatients } = useContext(PatientContext);
  const navigate = useNavigate();

  function handleDelete(id) {
    setLoader(dispatch, true);
    patientsAPI
      .deletePatient(id)
      .then((res) => {
        if (res.data.status === 200) {
          updatePatients(id);
        }
      })
      .finally(() => setLoader(dispatch, false));
  }

  const Author = ({ name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      {/*<MDAvatar src={team2} name={name} size="sm" />*/}
      <MDBox lineHeight={1}>
        <MDTypography
          display="block"
          variant="button"
          fontWeight="medium"
          textTransform={"capitalize"}
        >
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );
  const LastName = ({ name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      {/*<MDAvatar src={team2} name={name} size="sm" />*/}
      <MDBox lineHeight={1}>
        <MDTypography
          display="block"
          variant="button"
          fontWeight="medium"
          textTransform={"capitalize"}
        >
          {name}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
  const Job = ({ title }) => (
    <MDBox lineHeight={1} textAlign="left" display={"flex"} columnGap={5}>
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography
        component="a"
        href={`tel:${title}`}
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        <Icon color={"success"} fontSize={"small"}>
          phone
        </Icon>
      </MDTypography>
    </MDBox>
  );
  const Address = ({ address }) => (
    <MDBox ml={-1}>
      <MDTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
        onClick={() =>
          utils.openGoogleMaps(`${address[0].street_address},${address[0].plz} ${address[0].city}`)
        }
      >
        {address[0]?.city}
      </MDTypography>
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {address[0]?.street_address}
      </MDTypography>
    </MDBox>
  );
  const Employed = ({ employed }) => (
    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      {employed || "01-01-2022"}
    </MDTypography>
  );
  const Gender = ({ gender }) => (
    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      {gender}
    </MDTypography>
  );
  const Action = ({ action, id, name }) => (
    <MDBox display={"flex"} gap={1} alignItems={"center"}>
      <AlertDialog
        onAgree={(e) => handleDelete(id)}
        id={id}
        triggerText={"Delete"}
        title={"name"}
        disagreeText={"Cancel"}
        content={`Are you sure you want to delete " ${name}"?`}
        agreeText={"delete"}
      />
      <MDTypography
        variant="caption"
        color="text"
        fontWeight="medium"
        onClick={() => navigate(`/edit_patient?id=${id}`)}
      >
        {action}
      </MDTypography>
    </MDBox>
  );

  const rows =
    users &&
    users.map((user) => ({
      gender: <Gender gender={user.gender} />,
      author: <Author image={user.image} name={user.first_name} email={user.email} />,
      lastName: <LastName name={user.last_name} />,
      phone: <Job title={user.phone} />,
      address: <Address address={user.with_address} />,
      employed: <Employed employed={user.employed} />,
      action: <Action action="Edit" id={user.id} name={user.last_name} />,
    }));

  const columns = [
    { Header: "#", accessor: "gender", width: "4%", align: "left" },
    { Header: "First", accessor: "author", align: "left" },
    { Header: "Last Name", accessor: "lastName", align: "left" },
    { Header: "Phone", accessor: "phone", align: "left" },
    { Header: "Address", accessor: "address", align: "center" },
    // { Header: "Employed", accessor: "employed", align: "center" },
    { Header: "Action", accessor: "action", align: "center" },
  ];

  return (
    <DataTable
      table={{ rows, columns }}
      isSorted={false}
      entriesPerPage={true}
      showTotalEntries={false}
      canSearch={true}
    />
  );
};
export default React.memo(PatientsTable);
