import React, { createContext, useEffect, useState } from "react";
import { _getPatients, patientsAPI } from "../assets/apiFunctions";

export const PatientContext = createContext();

//eslint-disable-next-line
export const PatientContextProvider = ({ children }) => {
  const [rawad, setRawad] = React.useState("mmm");
  const [patients, setPatients] = useState(null);
  const [localLoader, setLocalLoader] = useState(false);
  function updatePatients(id) {
    if (!patients) return; // If patients is null, there's nothing to update

    // Filter out the patient with the specified id
    const updatedPatients = patients.filter((patient) => patient.id !== id);

    // Update the patients state with the filtered list
    setPatients(updatedPatients);
  }
  function getPatients() {
    setLocalLoader(true);
    patientsAPI
      .getPatients()
      .then((res) => setPatients(res.data.data))
      .finally(() => setLocalLoader(false));
  }
  useEffect(() => {
    getPatients();
  }, []);
  return (
    <PatientContext.Provider value={{ patients, localLoader, updatePatients }}>
      {children}
    </PatientContext.Provider>
  );
};
