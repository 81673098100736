import * as React from "react";
import PropTypes from "prop-types";
import MDTypography from "../MDTypography";
import Menu from "@mui/material/Menu";
import { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import { otherAPI, shiftsApi } from "../../assets/apiFunctions";
import { Icon } from "@mui/material";
import Divider from "@mui/material/Divider";
import MDBox from "../MDBox";

export default function PopupDialog(props) {
  const { id, shift, user, day, data } = props;
  const [open, setOpen] = React.useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [workSchedule, setWorkSchedule] = useState(shift?.id);
  const [select, setSelected] = useState(null);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const send = (data) => {
    const formData = new FormData();
    formData.append(
      "data",
      JSON.stringify({
        shift_id: data.id,
        user_id: user,
        day: day,
        work_schedule_id: workSchedule || null,
      })
    );
    shiftsApi.setUserShift(formData).then((res) => {
      setWorkSchedule(res.data.shift.id);
      setSelected(data);
    });
  };

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      {data &&
        data.map((d) => (
          <MenuItem
            onClick={() => {
              send(d);
              handleCloseMenu();
            }}
            color={"error"}
            key={d.id}
          >
            {d?.name}
          </MenuItem>
        ))}
      <MDTypography>no data found</MDTypography>
    </Menu>
  );
  //eslint-disable-next-line
  const SideLighting = ({ bgcolor }) => {
    return (
      <MDBox
        sx={{ position: "absolute", height: "100%", left: 0 }}
        width={"3px"}
        bgcolor={bgcolor}
      ></MDBox>
    );
  };
  return (
    <MDBox id={id} width={"100%"} display={"flex"}>
      {!select && !shift ? (
        <MDBox
          width={"100%"}
          px={2}
          py={6}
          display={"flex"}
          sx={{ position: "relative" }}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <SideLighting bgcolor={"warning"} />
          <MDTypography
            display="block"
            variant="button"
            fontWeight="medium"
            color={"inherit"}
            onClick={handleOpenMenu}
            sx={{ cursor: "pointer" }}
          >
            <Icon>add_circle</Icon>
          </MDTypography>
        </MDBox>
      ) : (
        <MDBox
          width={"100%"}
          px={2}
          py={6}
          sx={{ position: "relative" }}
          alignItems={"center"}
          display={"flex"}
          justifyContent={"center"}
        >
          <SideLighting bgcolor={"success"} />
          <MDTypography
            display="block"
            variant="button"
            fontWeight="medium"
            color={"inherit"}
            onClick={handleOpenMenu}
            textTransform={"capitalize"}
          >
            {select?.name || shift?.shift_name}
          </MDTypography>
        </MDBox>
      )}
      {renderMenu()}
    </MDBox>
  );
}
PopupDialog.propTypes = {
  data: PropTypes.array,
  day: PropTypes.string,
  shift: PropTypes.array,
  id: PropTypes.string.isRequired,
  user: PropTypes.string.isRequired,
};
