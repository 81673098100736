import React, { useEffect, useState } from "react";
import MDBox from "../../components/MDBox";
import Card from "@mui/material/Card";
import MDTypography from "../../components/MDTypography";
import { useParams } from "react-router-dom";
import { patientsAPI, usersAPI } from "../../assets/apiFunctions";
import { contentLoader } from "../../components/loader/loader";
import SubmitForm from "../../components/forms/submitForm";
import { FormHelperText, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import { DatePicker } from "../../components/datePicker";
import MdSelect from "../../components/MDRadio/MDRadio";
import inputs from "./patientInputs";
import { numberToArray } from "../../assets/assetsFunctions";

const EditPatient = (props) => {
  // get user id from route
  const [patientId, setPatientId] = useState();
  const options = ["admin", "user"];
  const [patientToUpdate, setPatientToUpdate] = useState();
  const [localLoader, setLocalLoader] = useState(false);
  //eslint-disable-next-line
  const handleChange = (e) => {
    setPatientToUpdate({ ...patientToUpdate, [e.target.name]: e.target.value });
  };
  function handleChangeDate(e) {
    setPatientToUpdate({ ...patientToUpdate, startDate: e });
  }
  const checkError = () => {
    setPatientToUpdate({ ...patientToUpdate, error: {} });
    const setUserError = (key, message) => {
      setPatientToUpdate({
        ...patientToUpdate,
        error: { ...patientToUpdate.error, [key]: true, [key + "message"]: message },
      });
    };
    Object.keys(patientToUpdate).forEach((key) => {
      const value = patientToUpdate[key];
      switch (key) {
        case "firstName":
          if (value.length < 3) {
            setUserError(key, "message");
          }
        case "password":
          if (user?.password > 8) {
            setUserError(key, "Password must be at least 8 characters.");
          }
        case "password":
          if (user?.password !== user.confirmPassword) {
            setUserError(key, "Passwords don't match.");
          }
      }
    });
  };
  const submit = (e) => {
    e.preventDefault();
    checkError();
    const formData = new FormData();
    formData.append("patient", JSON.stringify(patientToUpdate));
    // userToUpdate?.error &&
    //   Object.keys(userToUpdate?.error).length === 0 &&
    patientsAPI
      .updatePatient(formData, patientId)
      .then((res) => {
        if (res.data.status == true) {
          openSuccessSB();
          // setUser(userData);
        }
      })
      .catch((e) => console.log(e));
  };
  useEffect(() => {
    const search = window.location.search;

    // Create a URLSearchParams object from the search string
    const searchParams = new URLSearchParams(search);

    // Get the value of "param1"
    const id = searchParams.get("id");
    if (id !== null) {
      setPatientId(id);
      getPatientFromServer(id);
    }
  }, []);

  function getPatientFromServer(id) {
    setLocalLoader(true);
    id &&
      patientsAPI
        .getPatientById(id)
        .then((res) => setPatientToUpdate(res?.data?.patient_details))
        .catch((e) => console.log(e))
        .finally(() => setLocalLoader(false));
  }

  return (
    <MDBox pt={6} pb={3}>
      <Card sx={{ marginTop: 2 }}>
        <MDBox
          mx={2}
          mt={-3}
          py={3}
          px={2}
          variant="gradient"
          bgcolor="info"
          borderRadius="lg"
          coloredShadow="info"
        >
          <MDTypography variant="h6" color="white">
            Edit user
          </MDTypography>
        </MDBox>
        <MDBox pt={3}></MDBox>
        {localLoader && contentLoader()}
        {patientToUpdate ? (
          <SubmitForm
            user={patientToUpdate}
            inputs={inputs}
            submit={submit}
            handleChange={handleChange}
            // inputType={inputType}
          />
        ) : (
          !localLoader && (
            <MDTypography
              variant="h6"
              fontWeight="medium"
              sx={{ alignSelf: "center" }}
              textTransform="capitalize"
            >
              No user found
            </MDTypography>
          )
        )}
      </Card>
    </MDBox>
  );
};

export default EditPatient;
