import React, { useState } from "react";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import Grid from "@mui/material/Grid";
import { DatePicker } from "../../components/datePicker";
import moment from "moment/moment";
import { Stack } from "@mui/material";
import SubmitForm from "../../components/forms/submitForm";
import formFields from "./ratesInputs";
import { getRatesAvailabilityVariables } from "../../context/ratesAvailability";

const SidebarDetails = () => {
  const { handleChange, handleChangeDate, date, data, submit } = getRatesAvailabilityVariables();

  return (
    <MDBox border={0.5} borderColor={"gray"} px={1}>
      <Stack
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
        mt={2}
        gap={1}
      >
        <SubmitForm
          handleChange={handleChange}
          submit={submit}
          user={data}
          inputs={formFields}
          inputProps={{ md: 9, lg: 9 }}
        >
          <Grid container>
            <Grid item>
              <MDTypography
                textTransform={"uppercase"}
                variant="caption"
                fontWeight="bold"
                color="text"
                gutterBottom
              >
                Start Date
              </MDTypography>
              <DatePicker
                value={date.startDate}
                defaultValue={date.startDate}
                onChange={(e) =>
                  // setDate(e);
                  handleChangeDate("startDate", e)
                }
              />
            </Grid>
            <Grid item>
              <MDTypography
                textTransform={"uppercase"}
                variant="caption"
                fontWeight="bold"
                color="text"
                gutterBottom
              >
                End Date
              </MDTypography>
              <DatePicker
                value={date.endDate}
                defaultValue={date.endDate}
                onChange={(e) =>
                  // setDate(e);
                  handleChangeDate("endDate", e)
                }
              />
            </Grid>
          </Grid>
        </SubmitForm>
      </Stack>
    </MDBox>
  );
};

export default SidebarDetails;
