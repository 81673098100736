import React, { useEffect, useState } from "react";
import MDBox from "../../components/MDBox";
import Card from "@mui/material/Card";
import LayoutHeader from "../../components/layoutHeader/layoutHeader";
import Add from "./add";
import RoomsTypesTablesTable from "./roomsTypesTable";
import { roomsAPI } from "../../assets/apiFunctions";
import { useMaterialUIController } from "../../context";
import { utils } from "../../assets/assetsFunctions";

const Edit = (props) => {
  const [updateData, setUpdateData] = useState();
  useEffect(() => {
    const id = utils.getParamsFromUrl("id");
    if (id) {
      roomsAPI
        .getRoomTypes()
        .then((res) => setUpdateData(() => res.data.data.filter((e) => e.id === id)));
    }
  }, []);
  return (
    <MDBox pt={6} pb={3}>
      <Card sx={{ marginTop: 2 }}>
        <LayoutHeader title={"Rooms Add"} />
        <MDBox pt={3}></MDBox>
        <Add dataToUpdate={updateData} />
      </Card>
      <div>asd</div>
    </MDBox>
  );
};

export default Edit;
