/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useEffect, useRef, useState } from "react";

// import {_signIn} from "../../../assets/apiFunctions";

// react-router-dom components
import { Link, useNavigate, useLocation } from "react-router-dom";
import { setRoles, setlOGIN, useMaterialUIController, setLoader } from "../../../context";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
// import Grid from "@mui/material/Grid";
// import MuiLink from "@mui/material/Link";

// @mui icons
// import FacebookIcon from "@mui/icons-material/Facebook";
// import GitHubIcon from "@mui/icons-material/GitHub";
// import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { authAPI } from "../../../assets/apiFunctions";
import MDAlert from "../../../components/MDAlert";
import SubmitForm from "../../../components/forms/submitForm";
import UseFormData from "../../../hooks/useFormData";

const Basic = () => {
  const [rememberMe, setRememberMe] = useState(false);
  const submitButton = useRef();
  const [userInfo, setUserInfo] = useState({
    email: "",
    password: "",
  });
  const [errorMessage, setError] = useState(null);
  const [controller, dispatch] = useMaterialUIController();
  const location = useLocation();
  const history = useNavigate();

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  function handleErrorMessage(e) {
    let errorMessage = "";
    switch (e?.error_code) {
      case 104:
        errorMessage = "Your account is disabled";
        break; // Use 'break' to exit the switch block
      case 125:
        errorMessage = "Email or password is invalid";
        break; // Use 'break' to exit the switch block
      default:
        errorMessage = "An unknown error occurred";
    }
    setError(errorMessage);
  }

  const handleLogin = async () => {
    setError(null);
    if (userInfo?.email && userInfo?.password) {
      setLoader(dispatch, true);
      const formData = await UseFormData({ name: "data", data: userInfo });
      authAPI
        .signIn(formData)
        .then((res) => {
          if (res.data.message === "success") {
            setlOGIN(dispatch, res.data.data).then(() => {
              setRoles(dispatch, res.headers.get("roles"));
              history(location?.state?.from || "dashboard");
            });
          }
        })
        .catch((e) => {
          const errorData = e.response.data;
          handleErrorMessage(errorData);
        })
        .finally(() => setLoader(dispatch, false));
    }
  };
  const handleChange = (e) => {
    setUserInfo((prevUserInfo) => ({
      ...prevUserInfo,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        submitButton && submitButton?.current?.click();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // Don't forget to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgcolor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="bold" color="error" mt={1} p={2}>
            {process.env.REACT_APP_APP_NAME}
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="text"
                name={"email"}
                value={userInfo?.email}
                label="Email"
                fullWidth
                onChange={(e) => handleChange(e)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                name={"password"}
                label="Password"
                fullWidth
                value={userInfo?.password}
                onChange={(e) => handleChange(e)}
              />
            </MDBox>
            {/*<MDBox display="flex" alignItems="center" ml={-1}>*/}
            {/*  <Switch checked={rememberMe} onChange={handleSetRememberMe} />*/}
            {/*  <MDTypography*/}
            {/*    variant="button"*/}
            {/*    fontWeight="regular"*/}
            {/*    color="text"*/}
            {/*    onClick={handleSetRememberMe}*/}
            {/*    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}*/}
            {/*  >*/}
            {/*    &nbsp;&nbsp;Remember me*/}
            {/*  </MDTypography>*/}
            {/*</MDBox>*/}
            <MDBox mt={4} mb={1}>
              <MDButton
                ref={submitButton}
                variant="gradient"
                color="info"
                fullWidth
                onClick={() => handleLogin()}
              >
                sign in
              </MDButton>
            </MDBox>
            {errorMessage && (
              <MDAlert color="error" dismissible>
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="white"
                  sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                >
                  {errorMessage}
                </MDTypography>
              </MDAlert>
            )}
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                forget password?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/reset"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  reset
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
};

export default Basic;
