import React from "react";
import { contentLoader } from "../loader/loader";
import PropTypes from "prop-types";

const ConditionalRenderer = ({ data, isLoading, component }) => {
  const hasData = data && data.length > 0;
  if (isLoading) return contentLoader();
  return hasData ? component : <div>No data</div>;
};

export default ConditionalRenderer;
ConditionalRenderer.propTypes = {
  data: PropTypes.any.isRequired,
  isLoading: PropTypes.bool.isRequired,
  component: PropTypes.node.isRequired,
};
