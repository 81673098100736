/* eslint-disable react/prop-types */
import React from "react";
import { useShiftsContextVariable } from "../../context/shiftsContext";
import ConditionalRenderer from "../../components/ConditionalRenderer/conditionalRenderer";
import DataTable from "../../examples/Tables/DataTable";
import { Name, Shift } from "./components/shiftComponents";

const ShiftsView = () => {
  const { shifts, isLoading } = useShiftsContextVariable();
  const rows =
    shifts &&
    shifts.length > 0 &&
    shifts?.map((d) => ({
      name: <Name name={d.name} />,
      period: <Shift name={d} />,
    }));
  const columns = [
    { Header: "shift", accessor: "name" },
    { Header: "shift period", accessor: "period" },
  ];
  return (
    <ConditionalRenderer
      isLoading={isLoading}
      data={rows}
      component={
        <DataTable
          table={{ rows, columns }}
          isSorted={true}
          canSearch={false}
          entriesPerPage={false}
          showTotalEntries={false}
          noEndBorder
        />
      }
    />
  );
};

export default ShiftsView;
