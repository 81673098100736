import axiosInstance from "../axiosInstance";
import { prefix } from "./prefixsVariables";

export const departmentAPI = {
  setDepartment: async (data) => {
    return await axiosInstance.post(
      `${prefix.department}/dashboard/create_or_update_department`,
      data
    );
  },
  getDepartmentAPI: async (data) => {
    return await axiosInstance.get(`${prefix.department}/get_department`);
  },
  delete: async (id) => {
    return await axiosInstance.get(`${prefix.department}/delete/${id}`);
  },
};
