import React, { useEffect, useState } from "react";
import { usersAPI } from "../../../assets/apiFunctions";

const GetUserHook = () => {
  const [localLoader, setLocalLoader] = useState(false);
  const [user, setUser] = useState();
  const [userId, setUserId] = useState();
  const prepareUser = (res) => {
    const { id, ...data } = res?.user_details;
    const x = {
      ...data,
      contract_type: res.user_details.job_details?.fk_contract_details_id || null,
    };
    const f = {
      ...res,
      ...x,
      ...res.user_details,
    };
    console.log(f);
    setUser(f);
  };
  const getUserFromServer = (userId) => {
    setLocalLoader(true);
    userId &&
      usersAPI
        .getUserById(userId)
        .then((res) => prepareUser(res.data))
        .catch((e) => console.log(e))
        .finally(() => setLocalLoader(false));
  };
  useEffect(() => {
    const search = window.location.search;

    // Create a URLSearchParams object from the search string
    const searchParams = new URLSearchParams(search);

    // Get the value of "param1"
    const id = searchParams.get("id");
    if (id !== null) {
      setUserId(id);
      getUserFromServer(id);
    }
  }, []);
  return { user, localLoader, userId };
};

export default GetUserHook;
