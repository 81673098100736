import React, { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useMaterialUIController } from "../../context";

const Loader = (props) => {
  const [controller, dispatch] = useMaterialUIController();
  const { loader } = controller;
  // const [loader, setLoader] = useState(true);
  if (!loader) return null;
  return (
    <div
      style={{
        position: "fixed",
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(161,161,161,0.55)",
        zIndex: 1500,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress color={"info"} />
    </div>
  );
};

export default Loader;
export const contentLoader = () => {
  return (
    <div
      style={{
        color: "#dadada",
        alignSelf: "center",
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <CircularProgress color={"inherit"} />
    </div>
  );
};
