import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import MDButton from "../MDButton";
import MDBox from "../MDBox";

export default function AlertDialog(props) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <MDBox display={"flex"}>
        {props.icon && (
          <IconButton
            disabled={props.disable}
            style={{ marginRight: "10px" }}
            aria-label="delete"
            onClick={(e) => {
              e.stopPropagation(); // Prevent event propagation
              handleClickOpen();
            }}
          >
            {props.icon}
          </IconButton>
        )}
        {/*<IconButton*/}
        {/*  disabled={props.disable}*/}
        {/*  style={{ marginRight: "10px" }}*/}
        {/*  aria-label="delete"*/}
        {/*  onClick={(e) => {*/}
        {/*    e.stopPropagation(); // Prevent event propagation*/}
        {/*    handleClickOpen();*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <DeleteIcon color={props.disable ? "action" : "error"} />*/}
        {/*</IconButton>*/}
      </MDBox>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id={props.id}>{"Press delete to continue"}</DialogTitle>
        <DialogContent>
          <DialogContentText id={props.id}>{props.content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={(e) => {
              handleClose();
            }}
          >
            {props.disagreeText}
          </Button>
          <MDButton
            onClick={(e) => {
              props.onAgree(e);
              handleClose();
            }}
            color={"error"}
          >
            {props?.agreeText}
          </MDButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
AlertDialog.propTypes = {
  triggerText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  disagreeText: PropTypes.string.isRequired,
  agreeText: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onAgree: PropTypes.func.isRequired,
  icon: PropTypes.node,
  disable: PropTypes.bool,
};
