import React from "react";
import NestedModal from "../MDModal/modal";
import AddSickReport from "./addSickReport";

const SickReport = (props) => {
  return (
    <NestedModal>
      <AddSickReport />
    </NestedModal>
  );
};

export default SickReport;
