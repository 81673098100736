import PropTypes from "prop-types";

const useFormData = (childs) => {
  const formData = new FormData();
  const children = Array.isArray(childs) ? childs : [childs];

  children.forEach((d) => {
    formData.append(d.name, typeof d.data !== "string" ? JSON.stringify(d.data) : d.data);
  });

  return formData;
};

useFormData.propTypes = {
  childs: PropTypes.oneOfType([
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      data: PropTypes.any.isRequired,
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        data: PropTypes.any.isRequired,
      })
    ),
  ]).isRequired,
};

export default useFormData;
