import React, { useEffect, useState } from "react";

import { GetHoursBetweenDates } from "../../workHours";
import { DatePicker } from "../../../components/datePicker";
import MDBox from "../../../components/MDBox";
import MDButton from "../../../components/MDButton";
import { usersAPI, workingHoursAPI } from "../../../assets/apiFunctions";
import { useMaterialUIController, setLoader } from "../../../context";
import moment from "moment";
import ListView from "./listView";

import MultipleSelectPlaceholder from "../../../components/MDdropDown/MDdropDown";

import { getGlobalContext } from "../../../context/globalContext";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

const WorkHoursReport = (props) => {
  const today = moment();
  const [date, setDate] = React.useState({ startDate: today, endDate: today });
  const [controller, dispatch] = useMaterialUIController();
  const [data, setData] = useState();
  const { users } = getGlobalContext();
  const [selectedUser, setSelectedUser] = useState([]);

  function handleChangeDate(type, e) {
    setDate({ ...date, [type]: e });
  }

  function submit() {
    const usersIds = selectedUser.map((e) => e.id);
    setLoader(dispatch, true);
    workingHoursAPI
      .getHoursBetweenDates({
        start_date: date.startDate,
        end_date: date.endDate,
        user_id: usersIds,
      })
      .then((res) => {
        const working_days = res.data.working_days;
        setData(working_days);
      })
      .catch((e) => setData(null))
      .finally(() => setLoader(dispatch, false));
  }
  function selectUser(event) {
    const {
      target: { value },
    } = event;
    setSelectedUser(typeof value === "string" ? value.split(",") : value);
  }
  return (
    <>
      <MDBox p={3}>
        <Stack
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
          }}
          mt={2}
          gap={1}
        >
          <Grid item>
            <DatePicker
              value={date.startDate}
              defaultValue={date.startDate}
              onChange={(e) =>
                // setDate(e);
                handleChangeDate("startDate", e)
              }
            />{" "}
          </Grid>
          <Grid item>
            <DatePicker
              defaultValue={date.endDate}
              onChange={(e) =>
                // setDate(e);
                handleChangeDate("endDate", e)
              }
            />
          </Grid>
          <Grid item>
            <MDButton fullWidth variant={"outlined"} color={"info"} onClick={() => submit()}>
              Search
            </MDButton>
          </Grid>
        </Stack>
        {users && (
          <MultipleSelectPlaceholder
            options={users}
            handleChange={selectUser}
            users={selectedUser}
          />
        )}
        <Divider />
      </MDBox>

      {data && <ListView workingDays={data} totalHours={data?.total_hours} date={date} />}
    </>
  );
};

export default React.memo(WorkHoursReport);
